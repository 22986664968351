import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { ImageAvatarFallback } from '@/components'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

const AddRoleDialog = ({
  isOpen,
  onClose,
  person,
  s3Obj,
  onSaveRole,
  currentRole = ''
}) => {
  const [role, setRole] = useState(currentRole)

  // Reset role when dialog opens or person changes
  useEffect(() => {
    if (isOpen && person) {
      setRole(currentRole || '')
    }
  }, [isOpen, person, currentRole])

  const handleSaveRole = () => {
    if (person && role.trim()) {
      onSaveRole(person.people_id, role.trim())
      onClose()
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[400px]">
        <DialogHeader>
          <DialogTitle>Add Role</DialogTitle>
          <DialogDescription>
            Assign a role for this person in the matrix
          </DialogDescription>
        </DialogHeader>

        {person && (
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-3">
              <ImageAvatarFallback
                name={person.people_name}
                profilePic={
                  person.profile_picture ?? person.profile_picture_location
                }
                s3Obj={s3Obj}
                style={{
                  height: '36px',
                  minWidth: '36px',
                  maxWidth: '36px',
                  fontSize: '14px'
                }}
              />
              <div className="font-medium">{person.people_name}</div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="role">Role</Label>
              <Input
                id="role"
                placeholder="Enter role (e.g., Developer, Designer)"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              />
            </div>
          </div>
        )}

        <DialogFooter className="mt-4">
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            className="mr-2"
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleSaveRole}
            disabled={!role.trim()}
          >
            Save Role
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddRoleDialog
