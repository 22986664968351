import { useState, useEffect, useRef } from 'react'
import { Box, TextField, SvgIcon, IconButton } from '@mui/material'
import {
  ListTable,
  Container,
  Section,
  SectionFixed,
  TagFilter,
  Button,
  Loader
} from '../../components'
import { TooltipButton, GridSort } from '../../components/TableActions'
import { useStyles } from './styles'
import { postCollectionData } from '../../store/api'
import { useSelector } from 'react-redux'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import FilterListIcon from '@mui/icons-material/FilterList'
import { ReactComponent as SortDown } from '../../assets/svg/SortDown.svg'
import { ReactComponent as SortUp } from '../../assets/svg/SortUp.svg'
import moment from 'moment'
import AttachFilesUI from '@/components/AttachFilesUI/AttachFilesUI'

const fileExtensions = [
  {
    value: 'jpeg',
    label: 'JPEG'
  },
  {
    value: 'png',
    label: 'PNG'
  },
  {
    value: 'mp4',
    label: 'MP4'
  },
  {
    value: 'jpg',
    label: 'JPG'
  },
  {
    value: 'pdf',
    label: 'PDF'
  },
  {
    value: 'doc',
    label: 'DOC'
  },
  {
    value: 'ppt',
    label: 'PPT'
  }
]
const FileSelectorModal = (props) => {
  const {
    onSelectCallback = () => {},
    setResourceFiles = () => {},
    disableFiles = []
  } = props
  const classes = useStyles()
  const [page, setPage] = useState(null)
  const [pageData, setPageData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [tableLoading, setTableLoading] = useState(true)
  const [hasMoreData, setHasMoreData] = useState(true)
  const [tagsOptions, setTagsOptions] = useState(null)
  const [selectedTags, setSelectedTags] = useState(null)
  const [controllers, setControllers] = useState([])
  const tagsCenterStateTagsAll = useSelector((state) => state?.tagCenter?.tags)
  const [tagsCenterStateTags, setTagsCenterStateTags] = useState({})
  const [searchValue, setSearchValue] = useState('')
  const [isFiltersApplied, setIsFiltersApplied] = useState(false)
  const [prevFilters, setPrevFilters] = useState({})
  const [sortOrder, setSortOrder] = useState({
    created_at: 'desc'
  })
  const [selectedFiles, setSelectedFiles] = useState([])

  const searchInputRef = useRef(null)

  const onTableRowDoubleClick = (i, row) => {
    setResourceFiles(row)
  }

  const onTableRowClick = (i, row) => {
    const selectedIndex = selectedFiles.findIndex(
      (selectedRow) => selectedRow.id === row.id
    )
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedFiles, row)
    } else {
      selectedFiles.splice(selectedIndex, 1)
      newSelected = [...selectedFiles]
    }
    setSelectedFiles(newSelected)
  }
  const headCells = [
    {
      id: 'file_name',
      disablePadding: true,
      label: 'File Name',
      sort: true,
      width: '50%',
      onClick: onTableRowClick,
      onDoubleClick: onTableRowDoubleClick
    },
    {
      id: 'created_at',
      disablePadding: true,
      label: 'Created at',
      sort: true,
      width: '20%',
      onClick: onTableRowClick,
      onDoubleClick: onTableRowDoubleClick,
      format: (e) => {
        return moment.utc(e).local().format('lll')
      }
    },
    {
      id: 'created_by_user',
      disablePadding: true,
      label: 'Added by',
      sort: true,
      width: '30%',
      onClick: onTableRowClick,
      onDoubleClick: onTableRowDoubleClick
    }
  ]

  useEffect(() => {
    if (
      tagsCenterStateTagsAll &&
      !_.isEqual(
        Object.keys(tagsCenterStateTagsAll),
        Object.keys(tagsCenterStateTags)
      )
    ) {
      const filteredData = Object.keys(tagsCenterStateTagsAll || {}).reduce(
        (acc, key) => {
          const { key_type } = tagsCenterStateTagsAll[key] || {}
          if (key_type && key_type?.includes('document')) {
            acc[key] = tagsCenterStateTagsAll[key]
          }
          return acc
        },
        {}
      )
      setTagsCenterStateTags({ ...filteredData })
    }
  }, [tagsCenterStateTagsAll])

  useEffect(() => {
    const tagsValues = {}
    Object.keys(tagsCenterStateTags || {}).forEach((key, index) => {
      const {
        data = [],
        type,
        value_type,
        ...rest
      } = tagsCenterStateTags[key] || {}
      data.forEach((element) => {
        if (element.value) {
          if (tagsValues[key]?.values) {
            tagsValues[key].values.push({
              value: element.id,
              label: element.value
            })
          } else {
            if (!tagsValues[key]) tagsValues[key] = {}
            tagsValues[key].values = [
              {
                value: element.id,
                label: element.value
              }
            ]
          }
        }
        tagsValues[key] = {
          ...tagsValues[key],
          type,
          ...rest
        }
      })
    })

    setTagsOptions(tagsValues)
  }, [tagsCenterStateTags])

  const createAbortController = () => {
    const abortController = new AbortController()
    setControllers((prevControllers) => [...prevControllers, abortController])
    return abortController
  }
  const stopAllRequests = () => {
    controllers.forEach((controller) => controller.abort())
  }

  const GetSortIcon = ({ id, isSelected, order }) => {
    return (
      <Box className={classes.sortLabel}>
        <IconButton
          disableRipple
          disabled={tableLoading}
          onClick={() => {
            handleSort(id, 'asc')
          }}
        >
          <SvgIcon
            className={
              isSelected && order === 'asc'
                ? classes.sortIconActive
                : classes.sortIcon
            }
          >
            <SortUp />
          </SvgIcon>
        </IconButton>
        <IconButton
          disableRipple
          disabled={tableLoading}
          onClick={() => {
            handleSort(id, 'desc')
          }}
        >
          <SvgIcon
            className={
              isSelected && order === 'desc'
                ? classes.sortIconActive
                : classes.sortIcon
            }
          >
            <SortDown />
          </SvgIcon>
        </IconButton>
      </Box>
    )
  }

  const handleSort = (column, order) => {
    const newOrder = _.cloneDeep(sortOrder)
    if (newOrder?.[column] && newOrder[column] === order) {
      delete newOrder[column]
    } else {
      newOrder[column] = order
    }
    setSortOrder(newOrder)
    setPage(0)
    setTableLoading(true)
  }

  const handleSortColumnClick = (column) => {
    const order =
      sortOrder[column] === 'asc'
        ? 'desc'
        : sortOrder[column] === 'desc'
        ? ''
        : 'asc'
    handleSort(column, order)
  }

  const fetchData = async (page) => {
    setTableLoading(true)
    setSelectedFiles([])
    const abortController = createAbortController()
    const tag_values = []
    const tag_dates = []
    let file_extension = {}
    selectedTags &&
      Object.keys(selectedTags).forEach((key) => {
        if (key === 'file_extension') {
          const { condition, values = [] } = selectedTags[key] || {}
          const value = values.map((item) => item.value)
          file_extension = { condition: condition || 'is', values: value }
        } else if (key === 'collections_selected') {
          const tags = []
          let { condition, values = [] } = selectedTags[key] || {}
          values = values || []
          values.forEach((item) => {
            tags.push(item.id)
          })
          if (tags.length > 0) {
            tag_values.push({
              key,
              condition,
              values: tags
            })
          }
        } else {
          const tags = []
          let { condition, values = [] } = selectedTags[key] || {}
          values = values || []
          values.forEach((item) => {
            if (item?.type?.includes('date')) {
              tag_dates.push({ ...item, condition })
            } else {
              tags.push(item.value)
            }
          })
          if (tags.length > 0) {
            tag_values.push({
              key,
              condition,
              values: tags
            })
          }
        }
      })
    setPrevFilters({
      searchValue,
      sortOrder,
      selectedTags
    })
    const req = {
      page_num: page + 1,
      page_size: 30,
      type: 'all',
      keyword: searchValue,
      tags: tag_values,
      tag_dates,
      file_type: null,
      file_extension: _.isEmpty(file_extension) ? null : file_extension,
      hidden_manually: null,
      sort_order: sortOrder
    }
    setPage(page + 1)
    const isFiltersApplied =
      searchValue !== '' ||
      !_.isEmpty(file_extension) ||
      tag_values?.length > 0 ||
      tag_dates?.length > 0
    setIsFiltersApplied(isFiltersApplied)
    const res = await postCollectionData(req, {
      signal: abortController.signal
    })
    if (res.status === 200) {
      const { data } = res
      const { has_next_page, data: dataToAdd } = data || {}
      setHasMoreData(has_next_page)
      const disableFileIds = disableFiles.map((file) => file.file_id)
      const formattedData = dataToAdd.map((item) => {
        return {
          ...item,
          rowLoading: disableFileIds.includes(item.id)
        }
      })
      setPageData(formattedData)
      setLoading('')
      setTableLoading(false)
    }
  }

  useEffect(() => {
    setPage(0)
  }, [])

  useEffect(() => {
    if (page === 0) {
      fetchData(page)
      stopAllRequests()
    }
  }, [page])

  const handleTagsValueChange = (key, value, mode) => {
    if (mode === 'value') {
      if (_.isEmpty(value)) {
        const temp = { ...selectedTags }
        delete temp[key]
        setSelectedTags(temp)
      } else {
        if (selectedTags) {
          setSelectedTags({
            ...selectedTags,
            [key]: {
              ...selectedTags[key],
              values: value
            }
          })
        } else {
          setSelectedTags({
            [key]: {
              values: value
            }
          })
        }
      }
    }
    if (mode === 'condition') {
      if (selectedTags) {
        setSelectedTags({
          ...selectedTags,
          [key]: {
            ...selectedTags[key],
            condition: value
          }
        })
      } else {
        setSelectedTags({
          [key]: {
            condition: value
          }
        })
      }
    }
  }

  useEffect(() => {
    if (selectedTags !== null) {
      handleSearchQuery('tags', selectedTags)
    }
  }, [selectedTags])

  const handleSearchQuery = (reffer, newSelectedTags = selectedTags) => {
    if (reffer === 'tags') {
      const newFilters = {
        searchValue,
        sortOrder,
        newSelectedTags
      }
      if (_.isEqual(newFilters, prevFilters)) {
        return
      } else {
        setPage(0)
      }
    } else if (reffer === 'clear') {
      setPage(0)
    } else {
      if (searchInputRef.current) {
        searchInputRef.current.blur()
      }
      setPage(0)
    }
    setTableLoading(true)
  }

  const handleAddUploadedFilesToWorkspace = (files) => {
    if (!files || files.length === 0) {
      return
    }
    onSelectCallback(files)
    setResourceFiles(null)
  }

  return loading ? (
    <Loader loading={loading} caption={'loading files'} flex />
  ) : (
    <Container>
      <SectionFixed>
        <Box className={classes.searchWrapper}>
          <Box className={classes.searchBarWrapper}>
            <TextField
              inputRef={searchInputRef}
              variant="outlined"
              placeholder={'Search'}
              fullWidth
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearchQuery('search')
                }
                if (e.key === 'Backspace') {
                  if (!searchValue && searchCollection) {
                    setSearchCollection(null)
                  }
                }
              }}
              sx={{
                '& .MuiInputBase-root': {
                  paddingLeft: '5px'
                },
                '& input': {
                  padding: '8.5px 14px'
                }
              }}
              disabled={tableLoading}
            />
          </Box>
          <Box className={classes.searchButtonWrapper}>
            <TagFilter
              showCollectionOptions
              tags={{
                file_extension: {
                  values: fileExtensions,
                  type: 'default',
                  editable: false
                },
                ...tagsOptions
              }}
              showButton={true}
              showTags={false}
              disabled={tableLoading}
              selectedTags={selectedTags}
              onChange={handleTagsValueChange}
              clearFilter={
                isFiltersApplied && (
                  <Button
                    variant="outlined"
                    color="neutral"
                    sx={{ fontSize: '12px' }}
                    disabled={tableLoading}
                    onClick={() => {
                      if (!tableLoading) {
                        setSelectedTags(null)
                        setSearchValue('')
                        handleSearchQuery('clear')
                      }
                    }}
                  >
                    <TooltipButton
                      title={'Clear Filter'}
                      onBreak={<FilterListOffIcon sx={{ fontSize: '18px' }} />}
                    >
                      <Box className={classes.buttonTextWrapper}>
                        <FilterListOffIcon sx={{ fontSize: '18px' }} />
                        <Box className={classes.buttonText} component="span">
                          Clear Filter
                        </Box>
                      </Box>
                    </TooltipButton>
                  </Button>
                )
              }
              filterIcon={
                !isFiltersApplied && (
                  <Button
                    disabled={tableLoading}
                    variant="outlined"
                    color="neutral"
                    sx={{ fontSize: '12px' }}
                  >
                    <TooltipButton
                      title={'Filter'}
                      onBreak={<FilterListIcon sx={{ fontSize: '18px' }} />}
                    >
                      <Box className={classes.buttonTextWrapper}>
                        <FilterListIcon sx={{ fontSize: '18px' }} />
                        <Box className={classes.buttonText} component="span">
                          Filter
                        </Box>
                      </Box>
                    </TooltipButton>
                  </Button>
                )
              }
            />
            <GridSort
              disabled={tableLoading}
              sortOrder={sortOrder}
              columns={['file_name', 'created_at', 'created_by_user']}
              GetSortIcon={GetSortIcon}
              handleColumnClick={handleSortColumnClick}
              formatHeader={(column) => {
                return _.startCase(column)
              }}
            />

            <AttachFilesUI
              profileId={null}
              source="workspace"
              refreshGroup={() => {}}
              showFiles={false}
              additionalFileMetadata={{
                uploadfrom: 'workspace'
              }}
              setUploadCompleted={(files) => {
                handleAddUploadedFilesToWorkspace(files)
              }}
            />
          </Box>
        </Box>
        {selectedTags && Object.keys(selectedTags).length > 0 && (
          <Box className={classes.tagsWrapper}>
            <TagFilter
              tags={{
                file_extension: {
                  values: fileExtensions,
                  type: 'default',
                  editable: false
                },
                ...tagsOptions
              }}
              showButton={false}
              showTags={true}
              disabled={tableLoading}
              selectedTags={selectedTags}
              onChange={handleTagsValueChange}
              clearFilter={<></>}
            />
          </Box>
        )}
      </SectionFixed>
      <Section overFlow>
        <ListTable
          page={0}
          rows={pageData}
          pageSize={30}
          headCells={headCells}
          selectedRows={selectedFiles}
          setSelectedRows={setSelectedFiles}
          enableMultiSelect
          enableSort={false}
          enablePaginate
          paginationConfig={{
            type: 'prevnext',
            hasNextPage: hasMoreData
          }}
          handlePageChange={(page) => {
            fetchData(page - 1)
          }}
          loading={tableLoading}
          emptyMessage={<Box sx={{ textAlign: 'center' }}>No files found</Box>}
        />
      </Section>
      <SectionFixed>
        <Box
          sx={{
            display: 'flex',
            padding: '5px 15px',
            justifyContent: 'flex-end',
            gap: '10px'
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={selectedFiles.length === 0}
            onClick={() => {
              onSelectCallback(selectedFiles)
              setResourceFiles(null)
            }}
          >
            Select {selectedFiles.length} Files
          </Button>
        </Box>
      </SectionFixed>
    </Container>
  )
}

export default FileSelectorModal
