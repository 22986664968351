import React from 'react'
import { Dialog, DialogContent, DialogFooter } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import ScopeSelector from './ScopeSelector'

const AddScopeDialog = ({
  isOpen,
  onClose,
  onAddScopes,
  existingScopes,
  workspaceId
}) => {
  const [selectedScopes, setSelectedScopes] = React.useState(existingScopes)
  const handleAddScopes = () => {
    if (selectedScopes.length > 0) {
      console.log('selectedScopes', selectedScopes)
      onAddScopes(selectedScopes)
    }

    onClose()
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-[500px] max-h-[80vh] overflow-hidden flex flex-col">
        <div className="flex-1 p-1 px-6 -mx-6 overflow-auto">
          {isOpen && (
            <ScopeSelector
              workspaceId={workspaceId}
              scopeMatrixData={selectedScopes}
              setScopeMatrixData={setSelectedScopes}
            />
          )}
        </div>

        <DialogFooter className="mt-4">
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            className="mr-2"
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleAddScopes}
            disabled={selectedScopes.length === 0}
          >
            Add Scopes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddScopeDialog
