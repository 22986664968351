import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeadlessTable from '../../../components/TableV2/HeadlessTable'
import { initalizeS3 } from '../../../utils/AWS'
import { SemiCircleIcon } from '@/components/Icons/Icons'
import SearchNew from '@/sections/ListFilter/SearchNew'
import { fetchPeople } from '@/store/People/Actions'
import ResumeGrid from '@/sections/ResumeResult/ResumeGrid'
import { usePdfHandlers } from '@/hooks/PdfHooks'
import { ImageAvatarFallback } from '@/components'

const PeopleSelector = ({ peopleMatrixData, setPeopleMatrixData }) => {
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [meta, setMeta] = useState({})
  const [page, setPage] = useState(1)
  const [selectedPeople, setSelectedPeople] = useState(null)
  const [currentPeople, setCurrentPeople] = useState(null)
  const [selectedPeopleLoading, setSelectedPeopleLoading] = useState(false)
  const [s3Obj, setS3Obj] = useState(null)
  const dispatch = useDispatch()
  const [selectedData, setSelectedData] = useState([])
  const [search, setSearch] = useState('')
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id = '' } = domain || {}
  const { openPdf, downloadPdf } = usePdfHandlers(s3Obj, domain_id)

  // Columns configuration for the HeadlessTable
  const columns = [
    {
      accessorKey: 'name',
      label: 'Name',
      style: { width: '100%' },
      children: (row) => {
        return (
          <div className="flex items-center justify-between w-full p-1 text-sm font-medium text-zinc-800">
            <div className="flex items-center gap-2">
              <ImageAvatarFallback
                name={row?.name}
                profilePic={
                  row?.profile_picture ?? row?.profile_picture_location
                }
                style={{
                  height: '24px',
                  width: '24px',
                  fontSize: '12px'
                }}
                openImage={() => {}}
                s3Obj={s3Obj}
              />
              {row.name}
            </div>
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchData(page)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [search])

  useEffect(() => {
    if (peopleMatrixData.length > 0) {
      setSelectedData(peopleMatrixData.map((people) => people.people_id))
    }
  }, [])

  // Fetch projects data
  const fetchData = (pageNum = 1) => {
    setLoading(true)
    const req = {
      page_num: pageNum,
      page_size: 25,
      keyword: search
    }

    dispatch(
      fetchPeople(req, {}, (data) => {
        setRows(data?.peopleList)
        setLoading(false)
        setMeta({
          pageSize: 25,
          totalCount: data?.totalCount || 0,
          page: data?.currentPage || 1
        })
      })
    )
  }

  // Handle row click to display project details
  const handleRowClick = (row) => {
    // setSelectedProject(row)
    setSelectedPeopleLoading(true)
    dispatch(
      fetchPeople(
        {
          keyword: '',
          page_num: 1,
          page_size: 10,
          tags: [],
          tag_dates: [],
          master_people_id: row?.id,
          isSingleResume: true
        },
        {},
        (data) => {
          setCurrentPeople(data?.peopleList?.[0])
          setSelectedPeople(data)
          setSelectedPeopleLoading(false)
        }
      )
    )
  }
  const handleSelectedData = (row) => {
    if (selectedData.includes(row.id)) {
      setSelectedData(selectedData.filter((id) => id !== row.id))
      setPeopleMatrixData(
        peopleMatrixData.filter((people) => people.people_id !== row.id)
      )
    } else {
      setSelectedData([...selectedData, row.id])
      setPeopleMatrixData([
        ...peopleMatrixData,
        {
          people_id: row.id,
          people_name: row.name,
          people_role: null,
          profile_picture: row.profile_picture
        }
      ])
    }
  }
  // Initialize S3 and fetch data on component mount
  useEffect(() => {
    const initialize = async () => {
      const s3 = await initalizeS3()
      setS3Obj(s3)
      fetchData(page)
    }
    initialize()
  }, [])

  // Fetch data when page changes
  useEffect(() => {
    fetchData(page)
  }, [page])

  // Handle closing a project
  const handleClosePeople = () => {
    setSelectedPeople(null)
    setCurrentPeople(null)
  }

  return (
    <div className="w-full border-t twp border-zinc-100">
      <div
        className="grid w-full grid-cols-2"
        style={{ height: 'calc(100% - 55px)' }}
      >
        {/* Left panel - Projects list */}
        <div className="p-2 border-r border-zinc-200">
          <div className="p-2">
            <SearchNew
              value={search}
              placeholder="Search people"
              onChange={(value) => {
                setSearch(value)
              }}
            />
          </div>
          <HeadlessTable
            leftAlign={true}
            hideScrollbar={true}
            hideHeader={true}
            //   withoutStyles={true}
            //   hideMultiSelect={true}
            columns={columns}
            selectedData={selectedData}
            handleSelectedData={handleSelectedData}
            data={rows}
            meta={meta}
            loading={loading}
            onRowClick={handleRowClick}
            fetchPage={(pageNum) => setPage(pageNum)}
            style={{ height: 'calc(-190px + 90vh)', overflow: 'auto' }}
          />
        </div>

        {/* Right panel - Project details */}
        <div
          className=""
          style={{
            height: 'calc(100vh - 150px)',
            overflow: 'auto'
          }}
        >
          {selectedPeopleLoading ? (
            <div className="grid w-full h-full place-content-center">
              <SemiCircleIcon className="size-4 animate-spin-fast" />
            </div>
          ) : currentPeople ? (
            <ResumeGrid
              v2={true}
              people={currentPeople}
              setSelectedPerson={setSelectedPeople}
              isSuperUser={false}
              handleCloseProject={handleClosePeople}
              handleDownloadCustomPdf={(
                proposalId,
                pageNumber,
                thumbnail_url
              ) => downloadPdf(proposalId, pageNumber, thumbnail_url, 'resume')}
              s3Obj={s3Obj}
              openPdf={openPdf}
            />
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-grey-500">Select a project to view details</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PeopleSelector
