import Button2 from '@/components/Button/Button2'
import {
  ChevronRightIcon,
  PeopleIcon2,
  ProjectIcon,
  Table2
} from '@/components/Icons/Icons'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import PeopleSelector from '@/pages/Workspace/Matrix/PeopleSelector'
import ProjectSelector from '@/pages/Workspace/Matrix/ProjectSelector'
import ScopeSelector from '@/pages/Workspace/Matrix/ScopeSelector'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import GridMatrix from './GridMatrix'
import { getMatrixData, updateMatrixData } from '@/store/api'

const PeopleProjectMatrix = ({ workspaceId }) => {
  const [peopleMatrixData, setPeopleMatrixData] = useState([])
  const [projectMatrixData, setProjectMatrixData] = useState([])
  const [scopeMatrixData, setScopeMatrixData] = useState([])
  const [showGridMatrix, setShowGridMatrix] = useState(false)
  const [projectPeopleMatrixData, setProjectPeopleMatrixData] = useState({})
  const [projectScopeMatrixData, setProjectScopeMatrixData] = useState({})

  useEffect(() => {
    initializeMatrixData()
  }, [])

  const initializeMatrixData = async () => {
    const response = await getMatrixData(workspaceId)
    if (response.status === 200) {
      setPeopleMatrixData(response.data.people_ids_order_names)
      setProjectMatrixData(response.data.project_ids_order_names)
      setScopeMatrixData(response.data.scope_ids_order_names)
      setProjectPeopleMatrixData(
        response.data.project_people_relationships ?? {}
      )
      setProjectScopeMatrixData(response.data.project_scope_relationships ?? {})
      setShowGridMatrix(true)
    }

    if (response.status === 404) {
      setPeopleMatrixData([])
      setProjectMatrixData([])
      setScopeMatrixData([])
      setShowGridMatrix(false)
    }
  }

  const saveMatrixData = async () => {
    const projectIdsOrder = []
    const peopleIdsOrder = []
    const scopeIdsOrder = []

    projectMatrixData.forEach((project) => {
      projectIdsOrder.push(project.project_id)
    })

    peopleMatrixData.forEach((person) => {
      peopleIdsOrder.push(person.people_id)
    })

    scopeMatrixData.forEach((scope) => {
      scopeIdsOrder.push(scope.scope_id)
    })

    const data = {
      workspace_id: workspaceId,
      project_people_relationships: projectPeopleMatrixData,
      project_scope_relationships: projectScopeMatrixData,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder,
      scope_ids_order: scopeIdsOrder
    }
    const response = await updateMatrixData(data)

    if (response.status === 200) {
      toast.success('Matrix data saved successfully')
      setShowGridMatrix(true)
    } else {
      toast.error('Failed to save matrix data')
    }
  }

  return (
    <div
      className="overflow-hidden"
      style={{
        height: 'calc(100vh - 100px)'
      }}
    >
      <div className="relative flex items-center justify-between border-b twp border-zinc-100">
        {showGridMatrix ? (
          <GridMatrix
            workspaceId={workspaceId}
            peopleMatrixData={peopleMatrixData}
            projectMatrixData={projectMatrixData}
            projectPeopleMatrixData={projectPeopleMatrixData}
            projectScopeMatrixData={projectScopeMatrixData}
            scopeMatrixData={scopeMatrixData}
            goBack={() => setShowGridMatrix(false)}
          />
        ) : (
          <div className="relative flex items-center w-full gap-2 px-1">
            <Tabs defaultValue="projects" className="w-full p-2">
              <TabsList>
                <TabsTrigger value="projects">
                  <ProjectIcon className="mr-2 size-4" />
                  Projects
                </TabsTrigger>
                <TabsTrigger value="people">
                  <PeopleIcon2 className="mr-2 size-4" />
                  People
                </TabsTrigger>
                <TabsTrigger value="scope">
                  <Table2 className="mr-2 size-4" />
                  Scope
                </TabsTrigger>
              </TabsList>
              <TabsContent value="projects">
                <ProjectSelector
                  projectMatrixData={projectMatrixData}
                  setProjectMatrixData={setProjectMatrixData}
                />
              </TabsContent>
              <TabsContent value="people">
                <PeopleSelector
                  peopleMatrixData={peopleMatrixData}
                  setPeopleMatrixData={setPeopleMatrixData}
                />
              </TabsContent>
              <TabsContent value="scope">
                <ScopeSelector
                  scopeMatrixData={scopeMatrixData}
                  setScopeMatrixData={setScopeMatrixData}
                  workspaceId={workspaceId}
                />
              </TabsContent>
            </Tabs>

            <div
              className="absolute top-3 right-3 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={true}
            >
              <div className="flex items-center gap-2">
                <div className="text-sm font-medium ">
                  {projectMatrixData.length === 0 &&
                  peopleMatrixData.length === 0 &&
                  scopeMatrixData.length === 0 ? (
                    <p className="px-4 py-1 text-xs font-medium border rounded-full text-zinc-700 border-zinc-200">
                      Start selecting projects, people, or scope fields to
                      continue
                    </p>
                  ) : (
                    <div className="flex items-center text-sm font-medium border rounded-full border-zinc-200">
                      <div className="px-4 py-1 rounded-l-full bg-zinc-100">
                        Selected
                      </div>
                      <div className="flex gap-2 px-3 bg-white rounded-r-full">
                        {projectMatrixData.length > 0 && (
                          <p className="flex items-center gap-1">
                            {projectMatrixData.length} Projects
                          </p>
                        )}
                        {peopleMatrixData.length > 0 && (
                          <>
                            {projectMatrixData.length > 0 && <span>-</span>}
                            <p className="flex items-center gap-1">
                              {peopleMatrixData.length} People
                            </p>
                          </>
                        )}
                        {scopeMatrixData.length > 0 && (
                          <>
                            {(projectMatrixData.length > 0 ||
                              peopleMatrixData.length > 0) && <span>-</span>}
                            <p className="flex items-center gap-1">
                              {scopeMatrixData.length} Scope Fields
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <Button2
                  primary
                  onClick={() => {
                    saveMatrixData()
                  }}
                  disabled={
                    projectMatrixData.length === 0 &&
                    peopleMatrixData.length === 0 &&
                    scopeMatrixData.length === 0
                  }
                >
                  Select & Continue
                  <ChevronRightIcon className="size-3" />
                </Button2>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PeopleProjectMatrix
