import { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  getWorkspace,
  workspaceActionsFiles,
  workspaceActionsDrafts,
  getAllProposalNames,
  getAllRfxNames,
  getRFXPainPoints
} from '../../../store/api'
import {
  Container,
  Section,
  SectionFixed,
  Tabs,
  Button,
  ImageGallery,
  ReactSelect,
  TextInput
} from '../../../components'
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
  DialogContent
} from '@mui/material'
import { useStyles } from './styles'
import { toast } from 'react-toastify'
import { updateWorkspace } from '../../../store/Workspace/Actions'
import { useSelector, useDispatch } from 'react-redux'
import WorkspaceListFiles from '../../../sections/WorkspaceListFiles'
import WorkspaceListDrafts from '../../../sections/WorkspaceListDrafts'
import PeopleProjectMatrix from '../Matrix/PeopleProjectMatrix'
import FileSelector from '../../../sections/FileSelector'
import CloseIcon from '@mui/icons-material/Close'
import ResourceFileView from '../../../sections/ResourceFileView'
import {
  checkUserRoleSuperUser,
  checkUserRoleAdmin,
  checkAccessByRole
} from '../../../utils/User'
import { initalizeS3 } from '../../../utils/AWS'
import { v4 as uuidv4 } from 'uuid'
import { ROUTES } from '../../../config/routes'
import trackEvent from '../../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../../config/mixpanelEvents'
import Button2 from '../../../components/Button/Button2'
import {
  ChevronRightIcon,
  SemiCircleIcon,
  WorkspaceIcons
} from '@/components/Icons/Icons'
import { BorderBeam } from '@/components/ui/border-beam'

const Workspace = () => {
  const { workspaceId } = useParams()
  const location = useLocation()
  const {
    workspaceId: workspaceIdFromLocation,
    name: workspaceNameFromLocation
  } = location.state || {}
  const dispatch = useDispatch()
  const classes = useStyles()

  const [workspaceData, setWorkspaceData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingError, setLoadingError] = useState(null)
  const {
    workspace,
    workspace_files = [],
    workspace_drafts = []
  } = workspaceData || {}
  const { name, description } = workspace || {}
  const [activeTab, setActiveTab] = useState(0)
  const [addFile, setAddFile] = useState(false)
  const [addDraft, setAddDraft] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [imageGallery, setImageGallery] = useState([])
  const isSuperUser = checkUserRoleSuperUser()
  const isAdmin = checkUserRoleAdmin()
  const [s3Obj, sets3Obj] = useState({})
  const [draftConfig, setDraftConfig] = useState({})
  const [proposals, setProposals] = useState([])
  const [rfxs, setRfxs] = useState([])
  const [openFileViewer, setOpenFileViewer] = useState(false)
  const showWorkspaceAutomation =
    checkAccessByRole('workspace_automation_admin', 'admin') ||
    checkAccessByRole('workspace_automation_user', 'user')

  const { WORKSPACE } = ROUTES
  const navigate = useNavigate()
  const [showBorderBeam, setShowBorderBeam] = useState(true)

  const currentUserName = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )

  const onClose = () => {
    setOpenFileViewer(false)
    setTimeout(() => {
      setSelectedFile(null)
    }, 500)
  }

  useEffect(() => {
    if (selectedFile) {
      if (selectedFile.file_type === 'rfx') {
        setSelectedFile(null)
        const newPath = `/rfx/pdf/${selectedFile.id}/0`
        window.open(newPath, '_blank')
      } else {
        setOpenFileViewer(true)
      }
    }
  }, [selectedFile])

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
      const proposalRes = await getAllProposalNames()
      if (proposalRes.status === 200) {
        const { proposal_names } = proposalRes.data
        const formattedProposals = proposal_names.map((proposal) => {
          return {
            label: proposal.name,
            value: proposal.id
          }
        })
        setProposals(formattedProposals)
      }
      const rfxRes = await getAllRfxNames()
      if (rfxRes.status === 200) {
        const { rfx_list } = rfxRes.data
        const formattedRfxs = rfx_list.map((rfx) => {
          return {
            label: rfx.document_name,
            value: rfx.id
          }
        })
        setRfxs(formattedRfxs)
      }
    }
    initalizeData()
  }, [])

  const setFiles = (files) => {
    setWorkspaceData({
      ...workspaceData,
      workspace_files: files
    })
  }
  const setDrafts = (drafts) => {
    setWorkspaceData({
      ...workspaceData,
      workspace_drafts: drafts
    })
  }

  useEffect(() => {
    const fetchWorkspace = async () => {
      const res = await getWorkspace(workspaceId)
      if (res.status === 200) {
        setWorkspaceData(res.data)
        setLoading(false)
      } else {
        const apiError = 'Failed to load workspace'
        toast.error(apiError)
        setLoadingError(apiError)
      }
    }
    fetchWorkspace()
  }, [workspaceId])

  const handleSave = async (field, value) => {
    const request = {
      id: workspace.id,
      mode: 'update'
    }
    if (field === 'name') {
      request.workspace_name = value
      if (value === name) {
        return
      }
    } else if (field === 'description') {
      request.description = value
      if (value === description) {
        return
      }
    }
    const onSuccess = () => {
      setWorkspaceData({
        ...workspaceData,
        workspace: {
          ...workspace,
          [field]: value
        }
      })
    }
    const onFailure = () => {
      setWorkspaceData({
        ...workspaceData,
        workspace: {
          ...workspace,
          [field]: workspace[field]
        }
      })
    }
    dispatch(updateWorkspace(request, onSuccess, onFailure))
  }

  const tabs = showWorkspaceAutomation
    ? [
        {
          label: 'Drafts',
          children: (
            <>
              {workspace_drafts.length === 0 ? (
                <div className="relative grid h-full text-center place-content-center">
                  <div className="relative p-8 border rounded-lg shadow-sm border-zinc-200 twp">
                    <div className="flex items-center justify-center">
                      <div className="grid p-2 mb-2 rounded-lg shadow-sm bg-zinc-100 aspect-square place-content-center">
                        <WorkspaceIcons className="size-6" />
                      </div>
                    </div>
                    <p className="text-2xl font-medium">
                      Generate in Minutes, not Days
                    </p>
                    <p
                      style={{
                        fontSize: '0.75rem',
                        margin: 0
                      }}
                    >
                      Create a proposal draft in minutes with our easy-to-use
                      proposal builder.
                    </p>

                    <Box
                      sx={{
                        textAlign: 'left',
                        marginTop: '18px',
                        color: 'var(--dark-primary)',
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                      }}
                    >
                      <div style={{}}>
                        <p
                          style={{
                            margin: 0,
                            fontSize: '12px',
                            fontFamily: 'PoppinsMedium'
                          }}
                        >
                          Draft Name
                          <span
                            style={{
                              color: 'red',
                              marginLeft: '2px'
                            }}
                          >
                            *
                          </span>
                        </p>
                        <input
                          value={draftConfig?.name || ''}
                          style={{
                            width: '100%',
                            padding: '8px',
                            fontSize: '14px',
                            borderRadius: '5px',
                            border: '1px solid var(--grey-300)',
                            outline: 'none',
                            boxSizing: 'border-box'
                          }}
                          onChange={(e) => {
                            setDraftConfig({
                              ...draftConfig,
                              name: e.target.value
                            })
                          }}
                        />
                      </div>

                      {showWorkspaceAutomation && (
                        <>
                          <div
                            style={{
                              maxWidth: '450px'
                            }}
                          >
                            <p
                              style={{
                                margin: 0,
                                fontSize: '12px',
                                fontFamily: 'PoppinsMedium'
                              }}
                            >
                              Select an RFx
                              {/* <span
                              style={{
                                color: 'red',
                                marginLeft: '2px'
                              }}
                            >
                              *
                            </span> */}
                            </p>

                            <ReactSelect
                              options={rfxs}
                              placeholder="Select RFX"
                              isMulti={false}
                              isClearable
                              value={draftConfig?.rfx || null}
                              onChange={(data) => {
                                setDraftConfig({
                                  ...draftConfig,
                                  rfx: data
                                })
                              }}
                            />
                          </div>
                          <div
                            style={{
                              maxWidth: '450px'
                            }}
                          >
                            <p
                              style={{
                                margin: 0,
                                fontSize: '12px',
                                fontFamily: 'PoppinsMedium'
                              }}
                            >
                              Select Reference Proposals
                              {/* <span
                              style={{
                                color: 'red',
                                marginLeft: '2px'
                              }}
                            >
                              *
                            </span> */}
                            </p>{' '}
                            <ReactSelect
                              options={proposals}
                              placeholder="Select Proposals"
                              isMulti={true}
                              isClearable
                              value={draftConfig?.ref_proposals || null}
                              onChange={(data) => {
                                setDraftConfig({
                                  ...draftConfig,
                                  ref_proposals: data
                                })
                              }}
                            />
                          </div>
                        </>
                      )}
                      <DialogActions sx={{ marginTop: '8px' }}>
                        {/* <Button
                        onClick={() => {
                          setAddDraft(false)
                        }}
                      >
                        Cancel
                      </Button> */}
                        <Button
                          sx={{
                            width: '100%'
                          }}
                          // className={classes.splashBtn}
                          onClick={() => {
                            handleCreateDraft()
                          }}
                        >
                          Create
                        </Button>
                      </DialogActions>
                    </Box>

                    <BorderBeam
                      size={100}
                      duration={BORDER_BEAM_DURATION}
                      colorFrom="#FF3465"
                      colorTo="#471CA8"
                      containerStyle={{
                        zIndex: 1500,
                        borderWidth: '1.5px'
                      }}
                      hidden={!showBorderBeam}
                    />
                  </div>
                </div>
              ) : (
                <WorkspaceListDrafts
                  workspaceName={name}
                  drafts={workspace_drafts}
                  setDrafts={setDrafts}
                  workspace_id={workspaceId}
                  file_ids={workspace_files.map((file) => file.file_id)}
                />
              )}
            </>
          )
        },
        {
          label: 'Files',
          children: (
            <WorkspaceListFiles
              workspaceName={name}
              files={workspace_files}
              setFiles={setFiles}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              workspace_id={workspaceId}
            />
          )
        },
        {
          label: 'PPM',
          children: <PeopleProjectMatrix workspaceId={workspaceId} />
        }
      ]
    : [
        {
          label: 'Drafts',
          children: (
            <WorkspaceListDrafts
              drafts={workspace_drafts}
              setDrafts={setDrafts}
              workspace_id={workspaceId}
              file_ids={workspace_files.map((file) => file.file_id)}
            />
          )
        },
        {
          label: 'PPM',
          children: <PeopleProjectMatrix workspaceId={workspaceId} />
        }
      ]

  const handleCreateDraft = async () => {
    if (!draftConfig?.name) {
      toast.error('Draft name is required')
      return
    }
    let metadata = null
    if (draftConfig?.rfx || draftConfig?.ref_proposals) {
      // if (!draftConfig?.rfx || !draftConfig?.ref_proposals) {
      //   toast.error('RFX and Reference Proposals is required')
      //   return
      // }
      metadata = {
        rfx: draftConfig?.rfx,
        ref_proposals: draftConfig?.ref_proposals
      }
    }
    setAddDraft(false)
    const req = {
      id: uuidv4(),
      workspace_id: workspaceId,
      document_name: draftConfig?.name,
      type: metadata ? 'storyboard' : 'draft',
      metadata,
      created_by_user_name: currentUserName,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      mode: 'add',
      rowLoading: true
    }

    trackEvent(mixpanelEvents.WORKSPACE_DOCUMENT_CREATED, 'SUCCESS', {}, {})

    const res = await workspaceActionsDrafts(req)

    if (metadata?.rfx) {
      getRFXPainPoints(draftConfig?.rfx?.value)
    }

    if (res.status === 200) {
      const newDrafts = [...workspace_drafts, req].map((draft) => {
        if (draft.id === req.id) {
          draft.rowLoading = false
        }
        return draft
      })

      setDrafts(newDrafts)
      navigate(`${WORKSPACE}/${workspaceId}/${req.id}`)
    } else {
      toast.error('Failed to create draft')
      const newDrafts = [...workspace_drafts].filter(
        (draft) => draft.id !== req.id
      )
      setDrafts(newDrafts)
    }
  }
  const handleAddNewFile = ({ tab }) => {
    setShowBorderBeam(false)
    if (tab === 'file') {
      setAddFile(true)
    } else if (tab === 'draft') {
      // WORKSPACE_DOCUMENT_CREATED
      setAddDraft(true)
      setDraftConfig({})
    } else if (tab === 'PPM') {
      // The PPM component handles its own add dialog internally
      // Let's find the tab index for PPM
      const ppmTabIndex = tabs.findIndex((t) => t.label === 'PPM')
      if (ppmTabIndex !== -1) {
        setActiveTab(ppmTabIndex)
        // The PeopleProjectMatrix component will handle displaying its own add dialog
      }
    }
  }

  const onSelectCallback = async (selectedFiles) => {
    setAddFile(null)
    const req = []
    selectedFiles.forEach((file, i) => {
      req.push({
        id: uuidv4(),
        workspace_id: workspaceId,
        file_id: file.id,
        file_type: file.file_type,
        file_name: file.file_name,
        added_by_user_name: currentUserName,
        added_at: new Date().toISOString(),
        mode: 'add',
        rowLoading: true
      })
    })
    setWorkspaceData({
      ...workspaceData,
      workspace_files: [...workspace_files, ...req]
    })
    const res = await workspaceActionsFiles(req)
    if (res.status === 200) {
      const { data } = res
      const { errors = [], duplicates = [] } = data
      const removeIds = []
      if (!_.isEmpty(errors)) {
        const errorStr = errors.map((e) => e.file_name).join(', ')
        const errorIds = errors.map((e) => e.id)
        removeIds.push(...errorIds)
        toast.error(`Failed to add the following files: ${errorStr}`)
      }
      if (!_.isEmpty(duplicates)) {
        const duplicateStr = duplicates.map((d) => d.file_name).join(', ')
        const duplicateIds = duplicates.map((d) => d.id)
        removeIds.push(...duplicateIds)
        toast.warning(
          `The following files are already in the workspace: ${duplicateStr}`
        )
      }
      const newFiles = [...workspace_files, ...req]
        .filter((file) => !removeIds.includes(file.id))
        .map((file) => {
          if (file.rowLoading) {
            file.rowLoading = false
          }
          return file
        })
      setWorkspaceData({
        ...workspaceData,
        workspace_files: newFiles
      })
    } else {
      toast.error('Failed to add files')
    }
  }

  return (
    <Container>
      <div className="flex items-center gap-1 p-3.5 px-6 capitalize border-b twp border-zinc-100 text-zinc-800">
        <p
          className="flex items-center gap-1 text-xs font-medium cursor-pointer text-zinc-700"
          onClick={() => {
            navigate(`${WORKSPACE}`)
          }}
        >
          <WorkspaceIcons className="size-3" />
          Workspaces
        </p>
        <ChevronRightIcon className="size-2.5" />
        <p className="text-sm font-medium">
          {workspaceNameFromLocation || name}
        </p>
      </div>

      {loading ? (
        <div className="flex flex-col items-center justify-center h-full">
          <SemiCircleIcon className="size-4 animate-spin-fast" />
          <p className="text-xs font-medium"> Loading your workspace </p>
        </div>
      ) : (
        <Section overFlow>
          <Tabs
            sectionOverFlow
            data={tabs}
            isFlex={true}
            activeTab={activeTab}
            tabChangeCallBack={setActiveTab}
            extraContent={
              tabs[activeTab].label !== 'PPM' && (
                <Box className={classes.newButton}>
                  <Button2
                    primary
                    onClick={() =>
                      handleAddNewFile({
                        tab:
                          tabs[activeTab].label === 'Files' ? 'file' : 'draft'
                      })
                    }
                  >
                    {tabs[activeTab].label === 'Files'
                      ? '+ Add File'
                      : '+ Create Draft'}
                  </Button2>
                </Box>
              )
            }
          />
        </Section>
      )}
      <Dialog
        fullWidth
        maxWidth="md"
        disableEnforceFocus={true}
        sx={{ margin: 'auto', maxWidth: '500px' }}
        open={addDraft}
        onClose={() => setAddDraft(false)}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <Box className={classes.fileViwerWrapper}>
          <Box
            className={classes.splashTextStyle}
            style={{
              fontSize: '20px',
              padding: '10px 20px',
              fontWeight: '600'
            }}
          >
            Create Draft
          </Box>
          <DialogContent
            style={{
              paddingTop: '1px'
            }}
          >
            <Box className="text-sm">
              Draft Name
              <sup
                style={{
                  color: 'red'
                }}
              >
                *
              </sup>
            </Box>
            <TextInput
              value={draftConfig?.name || ''}
              onChange={(e) => {
                setDraftConfig({
                  ...draftConfig,
                  name: e.target.value
                })
              }}
            />
            {showWorkspaceAutomation && (
              <>
                <Box className="mt-3 text-sm">Select an RFx</Box>
                <ReactSelect
                  options={rfxs}
                  placeholder="Select RFX"
                  isMulti={false}
                  isClearable
                  value={draftConfig?.rfx || null}
                  onChange={(data) => {
                    trackEvent(
                      mixpanelEvents.WORKSPACE_RFX_SELECTED,
                      'SUCCESS',
                      {},
                      {}
                    )
                    setDraftConfig({
                      ...draftConfig,
                      rfx: data
                    })
                  }}
                />
                <Box className="mt-3 text-sm">Select Reference Proposals</Box>
                <ReactSelect
                  options={proposals}
                  placeholder="Select Proposals"
                  isMulti={true}
                  isClearable
                  value={draftConfig?.ref_proposals || null}
                  onChange={(data) => {
                    trackEvent(
                      mixpanelEvents.WORKSPACE_REFERENCE_PROPOSAL_SELECTED,
                      'SUCCESS',
                      {},
                      {}
                    )

                    setDraftConfig({
                      ...draftConfig,
                      ref_proposals: data
                    })
                  }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '10px 20px'
            }}
          >
            <Button2
              secondary
              noOutline
              onClick={() => {
                setAddDraft(false)
              }}
            >
              Cancel
            </Button2>
            <Button2
              primary
              onClick={() => {
                handleCreateDraft()
              }}
            >
              Create
            </Button2>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        fullScreen
        disableEnforceFocus={true}
        sx={{ margin: '5vh' }}
        open={addFile}
        onClose={() => setAddFile(false)}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <Box className={classes.fileViwerWrapper}>
          <Container>
            <SectionFixed>
              <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box>Add Files</Box>
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => setAddFile(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </DialogTitle>
            </SectionFixed>
            <Section overFlow>
              <FileSelector
                setResourceFiles={setSelectedFile}
                onSelectCallback={onSelectCallback}
                disableFiles={workspace_files}
              />
            </Section>
          </Container>
        </Box>
      </Dialog>
      <Dialog
        fullScreen
        sx={{ margin: '5vh' }}
        disableEnforceFocus={true}
        open={openFileViewer}
        onClose={onClose}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <Box className={classes.fileViwerWrapper}>
          <ResourceFileView
            s3Obj={s3Obj}
            file={selectedFile}
            setFile={setSelectedFile}
            onClose={onClose}
            isSuperUser={isSuperUser}
            isAdmin={isAdmin}
            setImageGallery={setImageGallery}
          />
        </Box>
      </Dialog>
      {imageGallery.length > 0 && (
        <ImageGallery
          images={imageGallery}
          onClose={() => setImageGallery([])}
          options={{ initialViewIndex: 0 }}
        />
      )}
    </Container>
  )
}

export default Workspace

const BORDER_BEAM_DURATION = 5

const EmptyWorkspace = ({ handleAddNewFile, handleAddNewDraft }) => {
  const [showBorderBeam, setShowBorderBeam] = useState(true)

  return (
    <div className="box-border flex items-center justify-around w-full h-full gap-10 p-20 twp">
      <div className="grid w-full h-full p-4 text-center rounded-lg place-content-center ">
        <div className="flex items-center justify-center">
          <div className="p-2 rounded-lg shadow-sm bg-zinc-100">
            <WorkspaceIcons className="size-6" />
          </div>
        </div>
        <p className="mt-3 text-2xl font-medium">Welcome to your workspace</p>
        <p className="text-sm text-zinc-800">
          Create a proposal draft from scratch or from an RFx.
        </p>
        <div className="flex items-center gap-4 mx-auto mt-16">
          <div
            className="flex flex-col justify-start gap-2 p-6 text-left transition-all duration-300 ease-in-out border rounded-lg cursor-pointer w-80 border-zinc-200 hover:border-zinc-400"
            onClick={() => handleAddNewFile({ tab: 'draft' })}
          >
            <p className="text-xl font-medium">Start with a blank draft</p>
            <p className="text-sm text-muted-foreground">
              Build your draft from the ground up. Customize it your way, with
              full control over the content.
            </p>
            <div className="mt-4">
              <Button2 secondary>Create Blank Draft</Button2>
            </div>
          </div>

          <div
            className="relative flex flex-col justify-start gap-2 p-6 text-left transition-all duration-300 ease-in-out border rounded-lg cursor-pointer w-80 border-zinc-200 hover:border-zinc-400"
            onClick={() => {
              setShowBorderBeam(false)
              handleAddNewFile({ tab: 'file' })
            }}
          >
            <p className="text-xl font-medium">Start with existing RFx</p>
            <p className="text-sm text-muted-foreground">
              Use an existing RFX to generate a draft instantly. Automatically
              generate storyboard for your Rfx.
            </p>
            <div className="mt-4">
              <Button2 primary>Select existing RFx</Button2>
            </div>
            <BorderBeam
              size={100}
              duration={BORDER_BEAM_DURATION}
              colorFrom="#FF3465"
              colorTo="#471CA8"
              containerStyle={{
                zIndex: 1500,
                borderWidth: '1.5px'
              }}
              hidden={!showBorderBeam}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
