import React, { useState, useEffect } from 'react'
import SearchNew from '../../../sections/ListFilter/SearchNew'
import { Checkbox } from '@/components/ui/checkbox'
import Button2 from '@/components/Button/Button2'
import { createScope, getScopes } from '@/store/api'

const ScopeSelector = ({
  scopeMatrixData,
  setScopeMatrixData,
  workspaceId
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredScopes, setFilteredScopes] = useState([])
  const [availableScopes, setAvailableScopes] = useState([])
  const [selectedScopes, setSelectedScopes] = useState([])

  // Convert scopes from scopeMatrixData to options format for SearchNew
  useEffect(() => {
    getScopes(workspaceId).then((res) => {
      if (res.status === 200) {
        setAvailableScopes(res.data)
        setSelectedScopes(scopeMatrixData.map((scope) => scope.scope_id))
        console.log('selectedScopes 3', scopeMatrixData)
      }
    })
  }, [])

  // Filter scopes based on search value
  useEffect(() => {
    if (searchValue) {
      const filtered = availableScopes.filter((scope) =>
        scope.scope.toLowerCase().includes(searchValue.toLowerCase())
      )
      setFilteredScopes(filtered)
    } else {
      setFilteredScopes(availableScopes)
    }
  }, [searchValue, availableScopes])

  // Handle adding a new scope if not found
  const handleAddScope = () => {
    if (!searchValue.trim()) return

    // Check if scope with this name already exists
    const existingScope = availableScopes.find(
      (scope) => scope.scope.toLowerCase() === searchValue.toLowerCase()
    )

    if (existingScope) {
      // If scope already exists, just select it
      handleToggleScope(existingScope)
    } else {
      createScope({
        workspace_id: workspaceId,
        scope: searchValue
      }).then((res) => {
        if (res.status === 200) {
          console.log('res', res.data)
          setAvailableScopes([
            ...availableScopes,
            {
              scope: searchValue,
              id: res?.data?.id
            }
          ])
          setSelectedScopes([...selectedScopes, res.data.id])

          setScopeMatrixData([
            ...scopeMatrixData,
            {
              scope_id: res?.data?.id,
              scope_name: searchValue
            }
          ])
        }
      })

      // Auto-select the new scope
      setSearchValue('')
    }
  }

  // Handle checkbox toggle for a scope
  const handleToggleScope = (scope) => {
    const scopeId = scope.id

    if (selectedScopes.includes(scopeId)) {
      // Deselect scope
      setSelectedScopes(selectedScopes.filter((id) => id !== scopeId))
      setScopeMatrixData(
        scopeMatrixData.filter((scope) => scope.scope_id !== scopeId)
      )
    } else {
      // Select scope
      setSelectedScopes([...selectedScopes, scopeId])
      setScopeMatrixData([
        ...scopeMatrixData,
        {
          scope_id: scopeId,
          scope_name: scope.scope
        }
      ])
    }
  }

  // Handle Enter key press - always add scope when Enter is pressed
  const handleEnterPress = (value) => {
    if (value.trim()) {
      setSearchValue(value.trim())
      handleAddScope()
    }
  }

  return (
    <div
      className="flex flex-col max-w-md gap-4 mx-auto"
      style={{
        height: 'calc(100% - 55px)'
      }}
    >
      <h1 className="text-xl font-semibold">Scope Selector</h1>

      <div className="flex items-center gap-2">
        <div className="flex-1">
          <SearchNew
            value={searchValue}
            onChange={(value) => {
              setSearchValue(value)
            }}
            onEnter={handleEnterPress}
            placeholder="Search for a scope..."
            onClear={() => setSearchValue('')}
          />
        </div>
        <Button2
          onClick={handleAddScope}
          primary
          disabled={!searchValue.trim() && filteredScopes.length === 0}
        >
          Add Scope
        </Button2>
      </div>

      {/* Display selected scopes or all available scopes */}
      <div className="mt-4">
        <div className="flex flex-col h-56 gap-2 overflow-y-auto">
          {filteredScopes.length > 0 ? (
            filteredScopes.map((scope) => (
              <div
                key={scope.scope || scope.scope_id}
                className="flex items-center px-3 py-2 text-sm border rounded-md cursor-pointer border-zinc-100 hover:bg-gray-50"
                onClick={() => handleToggleScope(scope)}
              >
                <div className="flex items-center gap-2">
                  <Checkbox
                    id={`scope-${scope.id}`}
                    checked={selectedScopes.includes(scope.id)}
                    className="mr-2"
                  />
                  <label
                    htmlFor={`scope-${scope.scope_id}`}
                    className="flex-1 cursor-pointer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {scope.scope}
                  </label>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">
              No scopes available. Add your first scope using the search above.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ScopeSelector
