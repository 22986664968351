import React, { useState, useEffect, useRef } from 'react'
import { Checkbox } from '@/components/ui/checkbox'
import {
  ChevronRightIcon,
  CrossIcon,
  DownloadIcon2,
  DragDotsIcon,
  PlusIcon,
  SwitchHorizontalIcon,
  ChevronDownIcon,
  ArrowsUpDownIcon
} from '@/components/Icons/Icons'
import { ImageAvatarFallback } from '@/components'
import * as XLSX from 'xlsx'
import AddPeopleDialog from './AddPeopleDialog'
import AddProjectDialog from './AddProjectDialog'
import AddScopeDialog from './AddScopeDialog'
import ReorderItemsDialog from './ReorderItemsDialog'
import PeopleCells from './PeopleCells'
import { changePeopleInfo, updateMatrixData } from '@/store/api'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'

// Create a simplified drag and drop component based on the example
const DraggableGrid = ({
  peopleMatrixData,
  projectMatrixData,
  projectPeopleMatrixData,
  projectScopeMatrixData,
  workspaceId,
  goBack,
  scopeMatrixData
}) => {
  const [matrixData, setMatrixData] = useState({})
  const [scopeRelationData, setScopeRelationData] = useState({})
  const [loading, setLoading] = useState(true)
  const [people, setPeople] = useState([])
  const [projects, setProjects] = useState([])
  const [scopes, setScopes] = useState([])
  const [peopleRoles, setPeopleRoles] = useState({}) // Map of personId -> role
  const [showAddPeopleDialog, setShowAddPeopleDialog] = useState(false)
  const [showAddProjectDialog, setShowAddProjectDialog] = useState(false)
  const [showScopeDialog, setShowScopeDialog] = useState(false)
  const [showRoleDialog, setShowRoleDialog] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState(null)
  const [showReorderDialog, setShowReorderDialog] = useState(false)
  const [reorderType, setReorderType] = useState(null) // 'people', 'project', or 'scope'
  const [matrixMode, setMatrixMode] = useState('project-people') // 'project-people' or 'people-project'
  const [viewType, setViewType] = useState('people') // 'people' or 'scope'
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const projectsContainerRef = useRef(null)
  const headerScrollRef = useRef(null)
  const relationshipDataRef = useRef(projectPeopleMatrixData)
  const peopleRolesRef = useRef({}) // Keep a ref for people roles to use in callbacks
  const scopeRelationshipDataRef = useRef(projectScopeMatrixData)
  const projectsSwapyRef = useRef(null)
  // Add custom styles for Swapy
  useEffect(() => {
    // Add styles to fix touch and drag issues
    const styleElement = document.createElement('style')
    styleElement.textContent = `
      .swapy-container {
        overscroll-behavior: contain;
      }
      [data-swapy-item] {
        touch-action: none;
        user-select: none;
      }
      [data-swapy-slot] {
        position: relative;
      }
      .swapy-dragging {
        z-index: 1000;
        opacity: 0.8;
      }
      .swapy-highlighted {
        background-color: rgba(59, 130, 246, 0.1);
        border: 2px dashed #3b82f6;
      }
      .matrix-header {
        position: sticky;
        top: 0;
        z-index: 10;
        background: #f9fafb;
        box-shadow: 0 1px 3px rgba(0,0,0,0.1);
      }
      .matrix-container {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 120px);
        overflow: hidden; /* Important to prevent double scrollbars */
      }
      .matrix-body {
        flex: 1;
        overflow: auto;
        // scrollbar-width: thin;
      }
      .dialog-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 50;
      }
      .dialog-content {
        background: white;
        border-radius: 0.5rem;
        width: 90%;
        max-width: 700px;
        max-height: 80vh;
        overflow: hidden;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
      }
    `
    document.head.appendChild(styleElement)

    return () => {
      // Clean up
      document.head.removeChild(styleElement)
    }
  }, [])

  // Initialize matrix data when peopleMatrixData or projectMatrixData changes
  useEffect(() => {
    if (peopleMatrixData.length > 0 && projectMatrixData.length > 0) {
      setPeople([...peopleMatrixData])
      setProjects([...projectMatrixData])

      const initialMatrix = {}
      projectMatrixData.forEach((project) => {
        initialMatrix[project.project_id] = {}
        peopleMatrixData.forEach((person) => {
          initialMatrix[project.project_id][person.people_id] = false
        })
      })

      setMatrixData(initialMatrix)

      // Initialize people roles from API data if available
      if (
        projectPeopleMatrixData &&
        Object.keys(projectPeopleMatrixData).length > 0
      ) {
        const initialRoles = {}

        // Check if there are roles in the relationship data
        Object.entries(projectPeopleMatrixData).forEach(
          ([projectId, personIds]) => {
            if (Array.isArray(personIds)) {
              // Simple array of person IDs without roles
              // Do nothing for roles here
            } else if (typeof personIds === 'object') {
              // If the API returns data with roles, process it
              // Example structure: { projectId: { personId: { assigned: true, role: 'Developer' } } }
              Object.entries(personIds).forEach(([personId, data]) => {
                if (data && data.role) {
                  initialRoles[personId] = data.role
                }
              })
            }
          }
        )

        setPeopleRoles(initialRoles)
        peopleRolesRef.current = initialRoles
      }

      setLoading(false)
    }
  }, [
    peopleMatrixData,
    projectMatrixData,
    projectPeopleMatrixData,
    projectScopeMatrixData
  ])

  // Initialize scopes if available
  useEffect(() => {
    if (scopeMatrixData && scopeMatrixData.length > 0) {
      setScopes(scopeMatrixData)

      // Initialize scope relation data structure if not already populated
      if (Object.keys(scopeRelationshipDataRef.current).length === 0) {
        const initialScopeRelations = {}
        projectMatrixData.forEach((project) => {
          initialScopeRelations[project.project_id] = []
        })
        scopeRelationshipDataRef.current = initialScopeRelations
        setScopeRelationData(initialScopeRelations)
      }
    }
  }, [scopeMatrixData, projectMatrixData])

  // Sync horizontal scrolling between header and body
  useEffect(() => {
    const projectsContainer = projectsContainerRef.current
    const headerContainer = headerScrollRef.current

    if (!projectsContainer || !headerContainer) return

    // Disable pointer events on header to prevent direct interaction
    // that would break sync, but enable them when dragging
    const headerParent = headerContainer.parentElement
    if (headerParent) {
      // headerParent.style.pointerEvents = 'none'
    }

    const handleProjectsScroll = () => {
      // Sync the horizontal scroll position from the projects container to the header
      headerContainer.scrollLeft = projectsContainer.scrollLeft
    }

    // Initial sync
    handleProjectsScroll()

    // Listen for scroll events on the projects container
    projectsContainer.addEventListener('scroll', handleProjectsScroll)

    return () => {
      projectsContainer.removeEventListener('scroll', handleProjectsScroll)
      if (headerParent) {
        headerParent.style.pointerEvents = ''
      }
    }
  }, [loading])

  // Enable pointerEvents only during dragging operations
  useEffect(() => {
    function enablePointerEvents() {
      const headerParent = headerScrollRef.current?.parentElement
      if (headerParent) {
        headerParent.style.pointerEvents = 'auto'
      }
    }

    function disablePointerEvents() {
      const headerParent = headerScrollRef.current?.parentElement
      if (headerParent) {
        // headerParent.style.pointerEvents = 'none'
      }
    }

    document.addEventListener('mousedown', enablePointerEvents)
    document.addEventListener('touchstart', enablePointerEvents)
    document.addEventListener('mouseup', disablePointerEvents)
    document.addEventListener('touchend', disablePointerEvents)

    return () => {
      document.removeEventListener('mousedown', enablePointerEvents)
      document.removeEventListener('touchstart', enablePointerEvents)
      document.removeEventListener('mouseup', disablePointerEvents)
      document.removeEventListener('touchend', disablePointerEvents)
    }
  }, [])

  // Handle checkbox changes for people
  const handleCheckboxChange = (projectId, personId, checked) => {
    setMatrixData((prev) => ({
      ...prev,
      [projectId]: {
        ...prev[projectId],
        [personId]: checked
      }
    }))

    let relationshipDataTemp = { ...relationshipDataRef.current }
    if (checked) {
      relationshipDataTemp = {
        ...relationshipDataRef.current,
        [projectId]: [
          ...(relationshipDataRef.current[projectId] || []),
          personId
        ]
      }
    } else {
      relationshipDataTemp = {
        ...relationshipDataRef.current,
        [projectId]: relationshipDataRef.current[projectId].filter(
          (id) => id !== personId
        )
      }
    }

    relationshipDataRef.current = relationshipDataTemp
    saveMatrixData(relationshipDataTemp)
  }

  // Handle checkbox changes for scopes
  const handleScopeCheckboxChange = (projectId, scopeId, checked) => {
    let scopeRelationDataTemp = { ...scopeRelationshipDataRef.current }

    if (checked) {
      scopeRelationDataTemp = {
        ...scopeRelationshipDataRef.current,
        [projectId]: [
          ...(scopeRelationshipDataRef.current[projectId] || []),
          scopeId
        ]
      }
    } else {
      scopeRelationDataTemp = {
        ...scopeRelationshipDataRef.current,
        [projectId]:
          scopeRelationshipDataRef.current[projectId]?.filter(
            (id) => id !== scopeId
          ) || []
      }
    }

    scopeRelationshipDataRef.current = scopeRelationDataTemp
    setScopeRelationData(scopeRelationDataTemp)
    saveScopeMatrixData(scopeRelationDataTemp)
  }

  const saveMatrixData = async (relationshipData) => {
    const projectIdsOrder = projectMatrixData.map(
      (project) => project.project_id
    )
    const peopleIdsOrder = peopleMatrixData.map((person) => person.people_id)
    const scopeIdsOrder = scopeMatrixData.map((scope) => scope.scope_id)

    const data = {
      workspace_id: workspaceId,
      project_people_relationships: relationshipData,
      project_scope_relationships: scopeRelationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder,
      scope_ids_order: scopeIdsOrder
    }
    const response = await updateMatrixData(data)
    console.log('response', response)
  }

  const saveScopeMatrixData = async (scopeRelationshipData) => {
    const projectIdsOrder = projectMatrixData.map(
      (project) => project.project_id
    )
    const scopeIdsOrder = scopeMatrixData.map((scope) => scope.scope_id)
    const peopleIdsOrder = peopleMatrixData.map((person) => person.people_id)

    const data = {
      workspace_id: workspaceId,
      project_people_relationships: relationshipDataRef.current,
      project_scope_relationships: scopeRelationshipData,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder,
      scope_ids_order: scopeIdsOrder
    }

    // Use the same API endpoint but send different data structure
    const response = await updateMatrixData(data)
    console.log('scope matrix response', response)
  }

  const handleItemOrderChange = async (type, slotItemMap) => {
    // Find the source array based on the type
    const sourceArray =
      type === 'people' ? people : type === 'project' ? projects : scopes

    // Find the setter function based on the type
    const setItems =
      type === 'people'
        ? setPeople
        : type === 'project'
        ? setProjects
        : setScopes

    // Create a new ordered array based on the slotItemMap
    const newItems = []
    slotItemMap.forEach((item) => {
      const foundItem = sourceArray.find((s) => s.id === item.item)
      if (foundItem) {
        newItems.push(foundItem)
      }
    })

    // Update the state with the new order
    setItems(newItems)

    if (type === 'people') {
      console.log(
        'New people mapping:',
        people,
        newItems,
        matrixData,
        relationshipDataRef.current
      )
    }

    // Prepare the data for the API call
    const projectIdsOrder =
      type === 'project'
        ? newItems.map((item) => item.id)
        : projects.map((project) => project.project_id)

    const peopleIdsOrder =
      type === 'people'
        ? newItems.map((item) => item.id)
        : people.map((person) => person.people_id)

    const scopeIdsOrder =
      type === 'scope'
        ? newItems.map((item) => item.id)
        : scopes.map((scope) => scope.scope_id)

    const data = {
      workspace_id: workspaceId,
      project_people_relationships: relationshipDataRef.current,
      project_scope_relationships: scopeRelationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder,
      scope_ids_order: scopeIdsOrder
    }

    // Call the API to update the data
    const response = await updateMatrixData(data)

    if (response.status === 200) {
      if (type === 'scope') {
        console.log('Scope order updated successfully')
      }
    }
  }

  const onPeopleOrderChange = (slotItemMap) =>
    handleItemOrderChange('people', slotItemMap)
  const onProjectOrderChange = (slotItemMap) =>
    handleItemOrderChange('project', slotItemMap)
  const onScopeOrderChange = (slotItemMap) =>
    handleItemOrderChange('scope', slotItemMap)

  // Toggle matrix mode between project-people and people-project
  const toggleMatrixMode = () => {
    setMatrixMode((prevMode) =>
      prevMode === 'project-people' ? 'people-project' : 'project-people'
    )
  }

  // Export to Excel with awareness of current matrix mode and view type
  const exportToExcel = () => {
    // Validate if there's data to export
    if (
      (viewType === 'people' && people.length === 0) ||
      (viewType === 'scope' && scopes.length === 0) ||
      projects.length === 0
    ) {
      console.error('No data to export')
      return
    }

    try {
      // Prepare the data for Excel based on current matrix mode and view type
      const excelData = []

      if (viewType === 'people') {
        // People view export logic
        if (matrixMode === 'project-people') {
          // Create header row with people names
          const headerRow = ['Projects / People']
          people.forEach((person) => {
            headerRow.push(person.people_name || 'Unknown Person')
          })
          excelData.push(headerRow)

          // Create rows for each project
          projects.forEach((project) => {
            const projectRow = [project.project_name || 'Unknown Project']
            people.forEach((person) => {
              const isAssigned = relationshipDataRef.current?.[
                project?.id
              ]?.includes(person.people_id)
              const personRole = peopleRoles[person.people_id]

              // Add role information if assigned and a role exists
              if (isAssigned) {
                if (personRole) {
                  projectRow.push(`X (${personRole})`)
                } else {
                  projectRow.push('X')
                }
              } else {
                projectRow.push('-')
              }
            })
            excelData.push(projectRow)
          })
        } else {
          // people-project mode - swap rows and columns
          const headerRow = ['People / Projects']
          projects.forEach((project) => {
            headerRow.push(project.project_name || 'Unknown Project')
          })
          excelData.push(headerRow)

          // Create rows for each person
          people.forEach((person) => {
            const personRole = peopleRoles[person.people_id]
            const personName = person.people_name || 'Unknown Person'
            const personRow = [
              personRole ? `${personName} (${personRole})` : personName
            ]

            projects.forEach((project) => {
              const isAssigned = relationshipDataRef.current?.[
                project?.id
              ]?.includes(person.people_id)
              personRow.push(isAssigned ? 'X' : '-')
            })
            excelData.push(personRow)
          })
        }
      } else {
        // Scope view export logic
        const headerRow = ['Projects / Scopes']
        scopes.forEach((scope) => {
          headerRow.push(scope.scope || 'Unknown Scope')
        })
        excelData.push(headerRow)

        // Create rows for each project
        projects.forEach((project) => {
          const projectRow = [project.project_name || 'Unknown Project']
          scopes.forEach((scope) => {
            // Add 'X' or '-' based on checkbox state
            projectRow.push(
              scopeRelationshipDataRef.current?.[project?.id]?.includes(
                scope.scope_id
              )
                ? 'X'
                : '-'
            )
          })
          excelData.push(projectRow)
        })
      }

      // Create worksheet
      const ws = XLSX.utils.aoa_to_sheet(excelData)

      // Set column widths for better readability
      const columnWidths = [{ wch: 20 }] // First column
      if (viewType === 'people' && matrixMode === 'project-people') {
        for (let i = 0; i < people.length; i++) {
          columnWidths.push({ wch: 20 }) // Increased width for People columns to accommodate roles
        }
      } else if (viewType === 'people') {
        for (let i = 0; i < projects.length; i++) {
          columnWidths.push({ wch: 15 }) // Project columns
        }
      } else {
        // Scope view
        for (let i = 0; i < scopes.length; i++) {
          columnWidths.push({ wch: 15 }) // Scope columns
        }
      }
      ws['!cols'] = columnWidths

      // Create workbook
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Matrix')

      // Generate file name with timestamp
      const timestamp = new Date()
        .toISOString()
        .replace(/[:.]/g, '-')
        .substring(0, 19)
      const fileName = `${
        viewType === 'people'
          ? matrixMode === 'project-people'
            ? 'Project_People'
            : 'People_Project'
          : 'Project_Scope'
      }_Matrix_${timestamp}.xlsx`

      // Generate Excel file and download
      XLSX.writeFile(wb, fileName)
    } catch (error) {
      console.error('Error exporting to Excel:', error)
    }
  }

  // Add new people to the matrix
  const handleAddPeople = async (newPeople) => {
    // Filter out already existing people
    const filteredNewPeople = newPeople.filter(
      (newPerson) =>
        !people.some((person) => person.people_id === newPerson.people_id)
    )

    if (filteredNewPeople.length === 0) return

    // Add new people to the state
    const updatedPeople = [...people, ...filteredNewPeople]
    setPeople(updatedPeople)

    console.log('updatedPeople', updatedPeople)
    // Initialize matrix data for new people
    const updatedMatrixData = { ...matrixData }
    projects.forEach((project) => {
      if (!updatedMatrixData[project.project_id]) {
        updatedMatrixData[project.project_id] = {}
      }

      filteredNewPeople.forEach((person) => {
        updatedMatrixData[project.project_id][person.people_id] = false
      })
    })

    setMatrixData(updatedMatrixData)

    const projectIdsOrder = projects.map((project) => project.project_id)
    const peopleIdsOrder = updatedPeople.map((person) => person.people_id)
    const scopeIdsOrder = scopes.map((scope) => scope.scope_id)
    const data = {
      workspace_id: workspaceId,
      project_people_relationships: relationshipDataRef.current,
      project_scope_relationships: scopeRelationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder,
      scope_ids_order: scopeIdsOrder
    }
    const response = await updateMatrixData(data)

    if (response.status === 200) {
      setShowAddPeopleDialog(false)
    }
  }

  const handleRemovePeople = async (personId) => {
    const updatedPeople = people.filter(
      (person) => person.people_id !== personId
    )
    setPeople(updatedPeople)

    const projectIdsOrder = []
    const peopleIdsOrder = []

    projects.forEach((project) => {
      projectIdsOrder.push(project.project_id)
    })

    updatedPeople.forEach((person) => {
      peopleIdsOrder.push(person.people_id)
    })

    const data = {
      workspace_id: workspaceId,
      relationship_data: relationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder,
      scope_ids_order: []
    }
    const response = await updateMatrixData(data)

    if (response.status === 200) {
    }
  }

  const handleRemoveProject = async (projectId) => {
    const updatedProjects = projects.filter(
      (project) => project.project_id !== projectId
    )
    setProjects(updatedProjects)

    const projectIdsOrder = []
    const peopleIdsOrder = []

    updatedProjects.forEach((project) => {
      projectIdsOrder.push(project.project_id)
    })

    people.forEach((person) => {
      peopleIdsOrder.push(person.people_id)
    })

    const scopeIdsOrder = scopes.map((scope) => scope.scope_id)

    const data = {
      workspace_id: workspaceId,
      project_people_relationships: relationshipDataRef.current,
      project_scope_relationships: scopeRelationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder,
      scope_ids_order: scopeIdsOrder
    }

    const response = await updateMatrixData(data)

    if (response.status === 200) {
    }
  }

  const handleAddPeopleClick = () => {
    setShowAddPeopleDialog(true)
  }

  // Add new projects to the matrix
  const handleAddProjects = async (newProjects) => {
    // Filter out already existing projects
    const filteredNewProjects = newProjects.filter(
      (newProject) =>
        !projects.some(
          (project) => project.project_id === newProject.project_id
        )
    )

    if (filteredNewProjects.length === 0) return

    // Add new projects to the state
    const updatedProjects = [...projects, ...filteredNewProjects]
    setProjects(updatedProjects)

    // Initialize matrix data for new projects
    const updatedMatrixData = { ...matrixData }
    filteredNewProjects.forEach((project) => {
      updatedMatrixData[project.project_id] = {}
      people.forEach((person) => {
        updatedMatrixData[project.project_id][person.people_id] = false
      })
    })

    setMatrixData(updatedMatrixData)

    const projectIdsOrder = updatedProjects.map((project) => project.project_id)
    const peopleIdsOrder = people.map((person) => person.people_id)
    const scopeIdsOrder = scopes.map((scope) => scope.scope_id)

    const data = {
      workspace_id: workspaceId,
      project_people_relationships: relationshipDataRef.current,
      project_scope_relationships: scopeRelationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder,
      scope_ids_order: scopeIdsOrder
    }

    const response = await updateMatrixData(data)

    if (response.status === 200) {
      setShowAddProjectDialog(false)
    }
  }

  const handleAddProjectClick = () => {
    setShowAddProjectDialog(true)
  }

  const handleRemoveScope = async (scopeId) => {
    const updatedScopes = scopes.filter((scope) => scope.scope_id !== scopeId)
    setScopes(updatedScopes)

    const projectIdsOrder = projects.map((project) => project.project_id)
    const peopleIdsOrder = people.map((person) => person.people_id)
    const scopeIdsOrder = updatedScopes.map((scope) => scope.scope_id)

    const data = {
      workspace_id: workspaceId,
      project_people_relationships: relationshipDataRef.current,
      project_scope_relationships: scopeRelationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder,
      scope_ids_order: scopeIdsOrder
    }
    const response = await updateMatrixData(data)

    if (response.status === 200) {
      console.log('Scope removed successfully')
    }
  }

  // Add scopes to the matrix
  const handleAddScopes = async (newScopes) => {
    // Filter out already existing scopes
    const filteredNewScopes = newScopes.filter(
      (newScope) =>
        !scopes.some((scope) => scope.scope_id === newScope.scope_id)
    )

    if (filteredNewScopes.length === 0) return

    // Add new scopes to the state
    const updatedScopes = [...scopes, ...filteredNewScopes]
    setScopes(updatedScopes)

    // Initialize relationship data for new scopes if needed
    // (this depends on how your scope relationship data is structured)

    const projectIdsOrder = projects.map((project) => project.project_id)
    const peopleIdsOrder = people.map((person) => person.people_id)
    const scopeIdsOrder = updatedScopes.map((scope) => scope.scope_id)

    const data = {
      workspace_id: workspaceId,
      project_people_relationships: relationshipDataRef.current,
      project_scope_relationships: scopeRelationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder,
      scope_ids_order: scopeIdsOrder
    }
    const response = await updateMatrixData(data)

    if (response.status === 200) {
      setShowScopeDialog(false)
      console.log('Scopes added successfully')
    }
  }

  const handleReorderItems = (result) => {
    // Extract data from result object
    const { projects: updatedProjects } = result

    // Update projects state
    setProjects(updatedProjects)

    // Update the specific item type being reordered
    if (reorderType === 'people' && result.people) {
      setPeople(result.people)
    } else if (reorderType === 'scope' && result.scopes) {
      setScopes(result.scopes)
    }

    // Prepare data for the API call
    const projectIdsOrder = updatedProjects.map((project) => project.project_id)
    const peopleIdsOrder =
      reorderType === 'people' && result.people
        ? result.people.map((person) => person.people_id)
        : people.map((person) => person.people_id)
    const scopeIdsOrder =
      reorderType === 'scope' && result.scopes
        ? result.scopes.map((scope) => scope.scope_id)
        : scopes.map((scope) => scope.scope_id)

    // Create data object for API call
    const data = {
      workspace_id: workspaceId,
      project_people_relationships: relationshipDataRef.current,
      project_scope_relationships: scopeRelationshipDataRef.current,
      project_ids_order: projectIdsOrder,
      people_ids_order: peopleIdsOrder,
      scope_ids_order: scopeIdsOrder
    }

    // Call API to update data
    updateMatrixData(data)
      .then((response) => {
        if (response.status === 200) {
          console.log('Items reordered successfully')
        }
      })
      .catch((error) => {
        console.error('Error updating order:', error)
      })
  }

  const openReorderDialog = (type) => {
    setReorderType(type)
    setShowReorderDialog(true)
  }

  // Handle role assignment for a person
  const handleSaveRole = async (personId, role) => {
    // Update local state
    changePeopleInfo({
      id: personId,
      role,
      workspace_id: workspaceId
    }).then((response) => {
      if (response.status === 200) {
        const updatedPeople = people.map((person) =>
          person.people_id === personId
            ? { ...person, people_role: role }
            : person
        )
        setPeople(updatedPeople)
      }
    })
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        Loading matrix...
      </div>
    )
  }

  if (people.length === 0 || projects.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        Please select at least one person and one project to view the matrix.
      </div>
    )
  }

  // Render matrix in project-people mode with scopes (default)
  const renderProjectScopeMatrix = () => (
    <>
      {/* Header row with scope names - sticky to top */}
      <div className="flex border-b border-gray-200 matrix-header">
        {/* Top-left corner cell with dropdown */}
        <div className="sticky left-0 z-20 flex items-center justify-between flex-shrink-0 w-64 p-2 text-sm font-bold border-r border-gray-200 min-w-64 bg-gray-50">
          <DropdownMenu>
            <DropdownMenuTrigger className="flex items-center gap-1 text-sm font-medium focus:outline-none">
              <span>
                Projects / {viewType === 'people' ? 'People' : 'Scope'}
              </span>
              <ChevronDownIcon className="size-3" />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuItem
                className={
                  viewType === 'people'
                    ? 'bg-zinc-50 text-zinc-800 font-medium text-sm'
                    : 'text-zinc-500'
                }
                onClick={() => setViewType('people')}
              >
                Projects - People
              </DropdownMenuItem>
              <DropdownMenuItem
                className={
                  viewType === 'scope'
                    ? 'bg-zinc-50 text-zinc-800 font-medium text-sm'
                    : 'text-zinc-500'
                }
                onClick={() => setViewType('scope')}
              >
                Projects - Scope
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          {viewType === 'people' && (
            <button
              onClick={toggleMatrixMode}
              className="p-1 rounded-md text-zinc-600 hover:bg-zinc-100"
              aria-label="Switch matrix orientation"
            >
              <SwitchHorizontalIcon className="size-3" />
            </button>
          )}
        </div>

        {/* Scope (columns) container */}
        <div
          ref={headerScrollRef}
          className="overflow-hidden"
          style={{ maxWidth: 'calc(100vw - 200px)' }}
        >
          <ScopeCells
            key={`scopes-${scopes.length}`}
            scopes={scopes}
            onScopeOrderChange={onScopeOrderChange}
            onAddScope={() => setShowScopeDialog(true)}
            onRemoveScope={handleRemoveScope}
          />
        </div>
      </div>

      {/* Projects (rows) container */}
      <div ref={projectsContainerRef} className="matrix-body">
        <div className="min-w-max">
          {projects.map((project) => (
            <div
              key={project.project_id}
              data-swapy-slot={project.project_id}
              className="w-full"
            >
              <div
                data-swapy-item={project.project_id}
                className="flex w-full border-y-[1px] border-gray-200 hover:bg-gray-50"
              >
                {/* Project name (fixed column) */}
                <div className="sticky left-0 flex items-center flex-shrink-0 w-64 p-3 pl-2.5 bg-white border-r border-gray-200 min-w-64 group cursor-grab active:cursor-grabbing">
                  <div className="mr-2 text-xs font-medium line-clamp-1">
                    {project.project_name || 'Unknown Project'}
                  </div>

                  <div
                    onClick={() => handleRemoveProject(project.project_id)}
                    className="ml-auto transition-all opacity-0 top-1 right-1 group-hover:opacity-100 text-zinc-500"
                  >
                    <CrossIcon className="size-2.5" />
                  </div>
                </div>

                {/* Checkboxes for each scope */}
                <div className="flex">
                  {scopes.map((scope) => (
                    <div
                      key={`${project.project_id}-${scope.scope_id}`}
                      className="flex items-center justify-center flex-shrink-0 p-3 border-r border-gray-200 w-36"
                    >
                      <Checkbox
                        checked={
                          scopeRelationshipDataRef.current?.[
                            project?.project_id
                          ]?.includes(scope.scope_id) || false
                        }
                        onCheckedChange={(checked) =>
                          handleScopeCheckboxChange(
                            project.project_id,
                            scope.scope_id,
                            checked
                          )
                        }
                        aria-label={`Assign ${scope.scope_name} to ${project.project_name}`}
                      />
                    </div>
                  ))}
                  {/* Empty cell for Add Scope column */}
                  <div className="flex items-center justify-center flex-shrink-0 p-3 border-r border-gray-200 w-36">
                    {/* This cell is intentionally left empty */}
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Add Project row */}
          <div className="flex w-full border-b border-gray-200 hover:bg-gray-50">
            <div className="sticky left-0 z-10 flex items-center flex-shrink-0 w-64 p-3 bg-white border-r border-gray-200 min-w-64">
              <button
                className="flex items-center justify-start w-full gap-2 text-xs font-medium text-zinc-600"
                aria-label="Add project to matrix"
                type="button"
                onClick={handleAddProjectClick}
              >
                <PlusIcon className="text-zinc-600 size-4" />
                <span>Add Project</span>
              </button>
            </div>

            {/* Empty cells for the add project row */}
            <div className="flex">
              {scopes.map((scope) => (
                <div
                  key={`add-project-${scope.scope_id}`}
                  className="flex-shrink-0 p-3 border-r border-gray-200 w-36"
                />
              ))}
              {/* Empty cell for the last column */}
              <div className="flex-shrink-0 p-3 border-r border-gray-200 w-36" />
            </div>
          </div>
        </div>
      </div>
    </>
  )

  // Render matrix in project-people mode (default)
  const renderProjectPeopleMatrix = () => (
    <>
      {/* Header row with people names - sticky to top */}
      <div className="flex border-b border-gray-200 matrix-header">
        {/* Top-left corner cell */}
        <div className="sticky left-0 z-20 flex items-center justify-between flex-shrink-0 w-64 p-2 text-sm font-bold border-r border-gray-200 min-w-64 bg-gray-50">
          <DropdownMenu>
            <DropdownMenuTrigger className="flex items-center gap-1 text-sm font-medium focus:outline-none">
              <span>
                Projects / {viewType === 'people' ? 'People' : 'Scope'}
              </span>
              <ChevronDownIcon className="size-3" />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuItem
                className={
                  viewType === 'people'
                    ? 'bg-zinc-50 text-zinc-800 font-medium text-sm'
                    : 'text-zinc-500'
                }
                onClick={() => setViewType('people')}
              >
                Projects - People
              </DropdownMenuItem>
              <DropdownMenuItem
                className={
                  viewType === 'scope'
                    ? 'bg-zinc-50 text-zinc-800 font-medium text-sm'
                    : 'text-zinc-500'
                }
                onClick={() => setViewType('scope')}
              >
                Projects - Scope
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {/* People (columns) draggable container */}
        <div
          ref={headerScrollRef}
          className="overflow-hidden"
          style={{ maxWidth: 'calc(100vw - 200px)' }}
        >
          <PeopleCells
            key={`people-${people.length}`}
            people={people}
            onPeopleOrderChange={onPeopleOrderChange}
            onAddPeople={handleAddPeopleClick}
            onRemovePeople={handleRemovePeople}
            peopleRoles={peopleRoles}
            onSaveRole={handleSaveRole}
          />
        </div>
      </div>

      {/* Projects (rows) draggable container - This is where all scrolling happens */}
      <div ref={projectsContainerRef} className="matrix-body">
        <div className="min-w-max">
          {projects.map((project) => (
            <div
              key={project.project_id}
              data-swapy-slot={project.project_id}
              className="w-full"
            >
              <div
                data-swapy-item={project.project_id}
                className="flex w-full border-y-[1px] border-gray-200 hover:bg-gray-50"
              >
                {/* Project name (fixed column) */}
                <div className="sticky left-0 flex items-center flex-shrink-0 w-64 p-3 pl-2.5 bg-white border-r border-gray-200 min-w-64 group cursor-grab active:cursor-grabbing">
                  <div className="mr-2 text-xs font-medium line-clamp-1">
                    {project.project_name || 'Unknown Project'}
                  </div>

                  <div
                    onClick={() => handleRemoveProject(project.project_id)}
                    className="ml-auto transition-all opacity-0 top-1 right-1 group-hover:opacity-100 text-zinc-500"
                  >
                    <CrossIcon className="size-2.5" />
                  </div>
                </div>

                {/* Checkboxes for each person */}
                <div className="flex">
                  {people.map((person) => {
                    const isAssigned =
                      relationshipDataRef.current?.[
                        project?.project_id
                      ]?.includes(person.people_id) || false

                    return (
                      <div
                        key={`${project.project_id}-${person.people_id}`}
                        className="flex flex-col items-center justify-center flex-shrink-0 p-2 border-r border-gray-200 w-36"
                      >
                        <Checkbox
                          checked={isAssigned}
                          onCheckedChange={(checked) =>
                            handleCheckboxChange(
                              project.project_id,
                              person.people_id,
                              checked
                            )
                          }
                          aria-label={`Assign ${person.people_name} to ${project.project_name}`}
                        />
                      </div>
                    )
                  })}
                  {/* Empty cell for Add People column */}
                  <div className="flex items-center justify-center flex-shrink-0 p-3 border-r border-gray-200 w-36">
                    {/* This cell is intentionally left empty */}
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Add Project row */}
          <div className="flex w-full border-b border-gray-200 hover:bg-gray-50">
            <div className="sticky left-0 z-10 flex items-center flex-shrink-0 w-64 p-3 bg-white border-r border-gray-200 min-w-64">
              <button
                className="flex items-center justify-start w-full gap-2 text-xs font-medium text-zinc-600"
                aria-label="Add project to matrix"
                type="button"
                onClick={handleAddProjectClick}
              >
                <PlusIcon className="text-zinc-600 size-4" />
                <span>Add Project</span>
              </button>
            </div>

            {/* Empty cells for the add project row */}
            <div className="flex">
              {people.map((person) => (
                <div
                  key={`add-project-${person.people_id}`}
                  className="flex-shrink-0 p-3 border-r border-gray-200 w-36"
                />
              ))}
              {/* Empty cell for the last column */}
              <div className="flex-shrink-0 p-3 border-r border-gray-200 w-36" />
            </div>
          </div>
        </div>
      </div>
    </>
  )

  // Render matrix in people-project mode
  const renderPeopleProjectMatrix = () => (
    <>
      {/* Header row with project names - sticky to top */}
      <div className="flex border-b border-gray-200 matrix-header">
        {/* Top-left corner cell */}
        <div className="sticky left-0 z-20 flex items-center justify-between flex-shrink-0 w-64 p-2 text-sm font-bold border-r border-gray-200 min-w-64 bg-gray-50">
          <DropdownMenu>
            <DropdownMenuTrigger className="flex items-center gap-1 text-sm font-medium focus:outline-none">
              <span>People / Projects</span>
            </DropdownMenuTrigger>
          </DropdownMenu>

          <button
            onClick={toggleMatrixMode}
            className="p-1 rounded-md text-zinc-600 hover:bg-zinc-100"
            aria-label="Switch matrix orientation"
          >
            <SwitchHorizontalIcon className="size-3" />
          </button>
        </div>

        {/* Projects (columns) draggable container */}
        <div
          ref={headerScrollRef}
          className="overflow-hidden"
          style={{ maxWidth: 'calc(100vw - 200px)' }}
        >
          <div className="flex min-w-max">
            {projects.map((project) => (
              <div
                key={project.project_id}
                className="relative flex items-center p-1 px-1.5 border-r w-36 slot top border-zinc-200 group pr-2"
                style={{ pointerEvents: 'auto' }}
              >
                <div
                  className="flex items-center justify-center w-full font-medium text-xxs item item-a"
                  style={{ pointerEvents: 'auto' }}
                >
                  <div className="ml-2 line-clamp-2">
                    {project.project_name}
                  </div>

                  <div
                    onClick={() => handleRemoveProject(project.project_id)}
                    className="ml-2 transition-all opacity-0 cursor-pointer top-1 right-1 group-hover:opacity-100 text-zinc-500"
                  >
                    <CrossIcon className="size-2.5" />
                  </div>
                </div>
              </div>
            ))}

            {/* Add Project column */}
            <div className="flex items-center justify-center p-1 px-3 border-r w-36 slot top border-zinc-200">
              <button
                className="flex flex-col items-center justify-center w-full gap-1 font-medium text-zinc-600 text-xxs"
                aria-label="Add project to matrix"
                type="button"
                onClick={handleAddProjectClick}
              >
                <PlusIcon className="text-zinc-600 size-4" />
                <span>Add Project</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* People (rows) container */}
      <div ref={projectsContainerRef} className="matrix-body">
        <div className="min-w-max">
          {people.map((person) => (
            <div key={person.people_id} className="w-full">
              <div className="flex w-full border-y-[1px] border-gray-200 hover:bg-gray-50">
                {/* Person name (fixed column) */}
                <div className="sticky left-0 z-10 flex items-center flex-shrink-0 w-64 p-3 pl-1 bg-white border-r border-gray-200 min-w-64 group">
                  <div className="flex items-center justify-center mr-1 transition-all opacity-0 cursor-pointer text-zinc-700 group-hover:opacity-100">
                    <DragDotsIcon className="size-3" />
                  </div>
                  <div className="flex items-center gap-2">
                    <ImageAvatarFallback
                      name={person.people_name}
                      profilePic={
                        person.profile_picture ??
                        person.profile_picture_location
                      }
                      style={{
                        height: '24px',
                        minWidth: '24px',
                        maxWidth: '24px',
                        fontSize: '12px'
                      }}
                      openImage={() => {}}
                      s3Obj={null}
                    />
                    <div className="mr-2 text-xs font-medium line-clamp-1">
                      {person.people_name}
                    </div>
                    {peopleRoles[person.people_id] && (
                      <div className="px-1.5 py-0.5 text-[9px] bg-zinc-100 border border-zinc-200 rounded">
                        {peopleRoles[person.people_id]}
                      </div>
                    )}
                  </div>

                  <div
                    onClick={() => handleRemovePeople(person.people_id)}
                    className="ml-auto transition-all opacity-0 top-1 right-1 group-hover:opacity-100 text-zinc-500"
                  >
                    <CrossIcon className="size-2.5" />
                  </div>
                </div>

                {/* Checkboxes for each project */}
                <div className="flex">
                  {projects.map((project) => {
                    console.log('checked', relationshipDataRef.current)
                    const isAssigned =
                      relationshipDataRef.current?.[
                        project?.project_id
                      ]?.includes(person.people_id) || false

                    return (
                      <div
                        key={`${person.people_id}-${project.project_id}`}
                        className="flex items-center justify-center flex-shrink-0 p-3 border-r border-gray-200 w-36"
                      >
                        <Checkbox
                          checked={isAssigned}
                          onCheckedChange={(checked) =>
                            handleCheckboxChange(
                              project.project_id,
                              person.people_id,
                              checked
                            )
                          }
                          aria-label={`Assign ${person.people_name} to ${project.project_name}`}
                        />
                      </div>
                    )
                  })}
                  {/* Empty cell for Add Project column */}
                  <div className="flex items-center justify-center flex-shrink-0 p-3 border-r border-gray-200 w-36">
                    {/* This cell is intentionally left empty */}
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Add People row */}
          <div className="flex w-full border-b border-gray-200 hover:bg-gray-50">
            <div className="sticky left-0 z-10 flex items-center flex-shrink-0 w-64 p-3 bg-white border-r border-gray-200 min-w-64">
              <button
                className="flex items-center justify-start w-full gap-2 text-xs font-medium text-zinc-600"
                aria-label="Add people to matrix"
                type="button"
                onClick={handleAddPeopleClick}
              >
                <PlusIcon className="text-zinc-600 size-4" />
                <span>Add People</span>
              </button>
            </div>

            {/* Empty cells for the add people row */}
            <div className="flex">
              {projects.map((project) => (
                <div
                  key={`add-people-${project.project_id}`}
                  className="flex-shrink-0 p-3 border-r border-gray-200 w-36"
                />
              ))}
              {/* Empty cell for the last column */}
              <div className="flex-shrink-0 p-3 border-r border-gray-200 w-36" />
            </div>
          </div>
        </div>
      </div>
    </>
  )

  // Add controls to the header area of the matrix
  const renderControls = () => (
    <div className="flex items-center justify-between p-2 px-4">
      <div
        className="flex items-center gap-1 text-sm font-medium cursor-pointer"
        onClick={() => goBack()}
      >
        <ChevronRightIcon className="rotate-180 size-4" /> Back to Selection
      </div>

      <div className="flex items-center gap-2">
        <button
          onClick={() => {
            if (viewType === 'people') {
              openReorderDialog('people')
            } else if (viewType === 'scope') {
              openReorderDialog('scope')
            }
          }}
          className="flex items-center gap-2 p-2 px-3 text-xs font-medium border rounded-md text-zinc-600 border-zinc-200 hover:bg-zinc-50"
          aria-label="Reorder People"
        >
          <ArrowsUpDownIcon className="size-4" strokeWidth={1.5} />
          Reorder
        </button>

        <button
          onClick={exportToExcel}
          className="flex items-center gap-2 p-2 px-4 text-xs font-medium text-white rounded-md bg-zinc-800 hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-offset-2"
          aria-label="Export to Excel"
        >
          <DownloadIcon2 className="size-4" strokeWidth={1.5} />
          Export to Excel
        </button>
      </div>
    </div>
  )

  return (
    <div className="flex flex-col w-full h-full twp">
      {renderControls()}
      <div className="relative mx-4 mb-4 border border-gray-200 rounded-md matrix-container">
        {/* Render different matrix layouts based on mode and view type */}
        {viewType === 'people'
          ? matrixMode === 'project-people'
            ? renderProjectPeopleMatrix()
            : renderPeopleProjectMatrix()
          : renderProjectScopeMatrix()}
      </div>

      {/* Add People Dialog */}
      <AddPeopleDialog
        isOpen={showAddPeopleDialog}
        onClose={() => {
          console.log('Closing dialog from onClose')
          setShowAddPeopleDialog(false)
        }}
        onAddPeople={handleAddPeople}
        existingPeople={people}
      />

      {/* Add Project Dialog */}
      <AddProjectDialog
        isOpen={showAddProjectDialog}
        onClose={() => setShowAddProjectDialog(false)}
        onAddProjects={handleAddProjects}
        existingProjects={projects}
      />

      {/* Add Scope Dialog */}
      {showScopeDialog && (
        <AddScopeDialog
          isOpen={showScopeDialog}
          onClose={() => setShowScopeDialog(false)}
          onAddScopes={handleAddScopes}
          existingScopes={scopes}
          workspaceId={workspaceId}
        />
      )}

      {/* Reorder Items Dialog */}
      {showReorderDialog && (
        <ReorderItemsDialog
          isOpen={showReorderDialog}
          onClose={() => setShowReorderDialog(false)}
          itemType={reorderType}
          items={
            reorderType === 'people'
              ? people
              : reorderType === 'project'
              ? projects
              : scopes
          }
          onSaveOrder={handleReorderItems}
          title={`Reorder ${
            reorderType === 'people'
              ? 'People'
              : reorderType === 'project'
              ? 'Projects'
              : 'Scopes'
          }`}
          allProjects={projects}
          allPeople={people}
          allScopes={scopes}
        />
      )}
    </div>
  )
}

export default DraggableGrid

function ScopeCells({ scopes, onAddScope, onRemoveScope }) {
  const containerRef = useRef(null)
  const [scopeOrder, setScopeOrder] = useState(scopes)
  console.log('scopeOrder', scopeOrder)
  useEffect(() => {
    setScopeOrder(scopes)
  }, [scopes])

  const handleAddScopeClick = () => {
    try {
      onAddScope()
    } catch (error) {
      console.error('Error calling onAddScope:', error)
    }
  }

  return (
    <div className="flex min-w-max" ref={containerRef}>
      {scopeOrder.map((scope) => (
        <div
          className="relative flex items-center justify-center p-1 px-1.5 border-r w-36 slot top border-zinc-200 group pr-2"
          key={scope.scope_id}
          style={{ pointerEvents: 'auto' }}
        >
          <div
            className="flex items-center justify-center w-full font-medium text-xxs item item-a"
            style={{ pointerEvents: 'auto' }}
          >
            <div className="flex items-center justify-between w-full gap-1">
              <div className="flex items-center">
                <div className="ml-2 line-clamp-2">{scope.scope_name}</div>
              </div>
              <div
                onClick={() => onRemoveScope(scope.scope_id)}
                className="transition-all opacity-0 cursor-pointer group-hover:opacity-100 text-zinc-500"
              >
                <CrossIcon className="size-2.5" />
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Add Scope column */}
      <div className="flex items-center justify-center p-1 px-3 border-r w-36 slot top border-zinc-200">
        <button
          className="flex flex-col items-center justify-center w-full gap-1 font-medium text-zinc-600 text-xxs"
          aria-label="Add scope to matrix"
          type="button"
          onClick={handleAddScopeClick}
        >
          <PlusIcon className="text-zinc-600 size-4" />
          <span>Add Scope</span>
        </button>
      </div>
    </div>
  )
}
