import React, { useState } from 'react'
import {
  CircleLoader,
  CrossIcon,
  PencilIcon,
  PlusIcon
} from '../../components/Icons/Icons'
import Button2 from '../../components/Button/Button2'
import { Dialog, DialogContent, Fade } from '@mui/material'
import { SelectDropDown } from '../../components'
import { updatePeopleInfo } from '../../store/People/Actions/UpdatePeople/UpdatePeople'
import mixpanelEvents from '../../config/mixpanelEvents'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

const EditProfileModal = ({
  open,
  setOpen,
  people,
  onUpdate,
  handleMasterResumeNameUpdate = () => {}
}) => {
  const [moving, setMoving] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState(null)
  const [search, setSearch] = useState('')
  const [fetchingPeople, setFetchingPeople] = useState(false)
  const [uploadedImage, setUploadedImage] = useState(null)
  const dispatch = useDispatch()
  console.log('people profile', people)
  const handleClose = () => {
    setSelectedPeople(null)
    setOpen(false)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const [basicInfo, setBasicInfo] = useState({
    id: people?.id,
    name: people?.name,
    logo: people?.profile_picture ?? people?.profile_picture_location,
    domain: 'Domain',
    location: 'Location',
    industry: 'Industry',
    tags: people?.tags ?? [],
    employeeType: people?.properties?.employee_type?.value ?? 'unknown',
    emails: people?.properties?.email_ids?.value ?? ['']
  })

  const handleProfileUpdate = (peopleInfo) => {
    // const id = toast.loading('Changing name...')
    const finalEmails = peopleInfo.emails.filter((email) => email !== '')
    const data = {
      id: peopleInfo.id,
      employee_name: peopleInfo.name,
      properties: {
        employee_type: {
          verified: peopleInfo.employeeType !== 'unknown',
          value: peopleInfo.employeeType
        },
        email_ids: {
          verified: finalEmails.length > 0,
          value: finalEmails
        }
      }
    }

    if (uploadedImage) {
      data.profile_picture = uploadedImage
    }

    const validationError = finalEmails.some((email) => !validateEmail(email))

    if (validationError) {
      toast.error('Please enter a valid email address')
      return
    }

    setMoving(true)

    const callback = () => {
      // setEditedName('')
      // toast.dismiss(id)
      onUpdate()
      setMoving(false)
    }
    trackEvent(
      mixpanelEvents.PEOPLE_PROFILE_UPDATED,
      'Success',
      {},
      {
        ...data
      }
    )
    console.log('data', data)
    dispatch(updatePeopleInfo(data, callback, true))
  }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Fade}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div
        className="p-3 border-box text-sm"
        style={{
          width: '450px'
        }}
      >
        <DialogContent
          style={{
            padding: '0',
            minHeight: '400px',
            overflow: 'hidden'
          }}
        >
          <div className="">
            <div
              className="flex justify-center"
              style={{
                height: '100px'
              }}
            >
              {' '}
              <label
                htmlFor="profileUpload"
                className="relative cursor-pointer"
              >
                <input
                  id="profileUpload"
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    const file = e.target.files?.[0]
                    if (file) {
                      const reader = new FileReader()
                      reader.onloadend = () => {
                        const base64String = reader.result
                          ?.toString()
                          .split(',')[1]
                        if (base64String) {
                          const prefix = `data:${file.type};base64,`
                          const src = prefix + base64String

                          if (src) {
                            setBasicInfo({
                              ...basicInfo,
                              logo: src
                            })
                            setUploadedImage({
                              src,
                              name: file.name
                            })
                          }
                        }
                      }
                      reader.readAsDataURL(file)
                    }
                  }}
                />
                {basicInfo.logo ? (
                  <img
                    src={basicInfo.logo}
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'cover',
                      borderRadius: '10px',
                      border: '1px solid var(--grey-200)'
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: '100px',
                      height: '100px',
                      // padding: '6px',
                      borderRadius: '10px',
                      border: '1px solid var(--grey-200)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'var(--grey-100)'
                    }}
                  >
                    {basicInfo.name?.split(' ')?.[0]?.[0]}
                    {basicInfo.name?.split(' ')?.[1]?.[0]
                      ? basicInfo.name?.split(' ')?.[1]?.[0]
                      : basicInfo.name?.split(' ')?.[0]?.[1]?.toUpperCase()}
                  </div>
                )}
                <span className="absolute bottom-2 right-2 bg-white rounded-full p-1 border-grey-200 grid place-content-center">
                  <PencilIcon className="size-3" />
                </span>
              </label>
            </div>
            <div className="text-left">
              <span className="text-xs text-grey-600 ml-1 ">Name</span>
              <input
                type="text"
                className="input-base capitalize outline-none text-lg w-full border-grey-200 border-1 p-2 rounded text-sm border-box mt-1"
                autoFocus
                value={basicInfo?.name}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                onChange={(e) => {
                  e.stopPropagation()
                  setBasicInfo({
                    ...basicInfo,
                    name: e.target.value
                  })
                }}
                onBlur={(e) => {
                  e.stopPropagation()
                }}
              />
            </div>
            <div className="text-left mt-4">
              <span className="text-xs text-grey-600 ml-1">Employee type</span>
              <div className="mt-1">
                <SelectDropDown
                  name="employeeType"
                  label=""
                  value={basicInfo.employeeType}
                  options={[
                    { label: 'Internal', value: 'internal' },
                    { label: 'External', value: 'external' },
                    { label: 'Unknown', value: 'unknown' },
                    { label: 'Inactive', value: 'inactive' }
                  ]}
                  handleChange={(e) => {
                    setBasicInfo({
                      ...basicInfo,
                      employeeType: e
                    })
                  }}
                />
              </div>
            </div>
            <div className="text-left mt-4">
              <div className="flex gap-1 items-center mb-1">
                <span className="text-xs text-grey-600 ml-1">Emails</span>

                <Button2
                  secondary
                  noOutline={true}
                  onClick={() => {
                    setBasicInfo({
                      ...basicInfo,
                      emails: [...basicInfo.emails, '']
                    })
                  }}
                  style={{
                    // marginTop: '4px',
                    padding: '3px'
                  }}
                >
                  {' '}
                  <PlusIcon className="size-4" />
                </Button2>
              </div>

              {basicInfo.emails.length <= 1 ? (
                <input
                  type="text"
                  className="input-base outline-none text-lg w-full border-grey-200 border-1 p-2 rounded text-sm border-box"
                  style={{}}
                  value={basicInfo?.emails[0]}
                  placeholder="john@example.org"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  onChange={(e) => {
                    e.stopPropagation()
                    setBasicInfo({
                      ...basicInfo,
                      emails: [e.target.value]
                    })
                  }}
                  onBlur={(e) => {
                    e.stopPropagation()
                  }}
                />
              ) : (
                <div className="flex flex-col gap-2">
                  {basicInfo.emails.map((email, index) => {
                    return (
                      <div key={index} className="flex items-center gap-1">
                        <input
                          type="text"
                          className="input-base capitalize outline-none text-lg w-full border-grey-200 border-1 p-2 rounded text-sm border-box"
                          style={{}}
                          autoFocus
                          value={email}
                          placeholder="Enter email"
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          onChange={(e) => {
                            e.stopPropagation()
                            setBasicInfo({
                              ...basicInfo,
                              emails: basicInfo.emails.map((item, i) => {
                                if (i === index) {
                                  return e.target.value
                                }
                                return item
                              })
                            })
                          }}
                          onBlur={(e) => {
                            e.stopPropagation()
                          }}
                        />
                        <Button2
                          secondary
                          noOutline
                          onClick={() => {
                            setBasicInfo({
                              ...basicInfo,
                              emails: basicInfo.emails.filter(
                                (item, i) => i !== index
                              )
                            })
                          }}
                          style={{
                            padding: '6px 8px'
                          }}
                        >
                          <CrossIcon className="size-4" />
                        </Button2>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </div>
      <div className="flex justify-end gap-2 items-center p-2 border-t-1">
        <Button2
          secondary
          noOutline={true}
          onClick={handleClose}
          style={{
            padding: '8px 16px'
          }}
        >
          Cancel
        </Button2>
        <Button2
          onClick={() => {
            // setMoving(true)
            // onMove(selectedPeople)
            handleProfileUpdate(basicInfo)
          }}
          //   disabled={!selectedPeople}
          primary
          style={{
            padding: '8px 16px'
          }}
        >
          {moving ? (
            <CircleLoader className="animate-spin text-white size-4" />
          ) : (
            'Update'
          )}
        </Button2>
      </div>
    </Dialog>
  )
}

export default EditProfileModal
