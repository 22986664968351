import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { DragDotsIcon } from '@/components/Icons/Icons'
import { ImageAvatarFallback } from '@/components'
import { createSwapy } from 'swapy'
import { initalizeS3 } from '@/utils/AWS'

const ReorderItemsDialog = ({
  isOpen,
  onClose,
  itemType, // 'people', 'project', or 'scope'
  items,
  onSaveOrder,
  title,
  allProjects = [], // Add props for all items for two-column layout
  allPeople = [],
  allScopes = []
}) => {
  // State for all item types
  const [projectItems, setProjectItems] = useState([...allProjects])
  const [peopleItems, setPeopleItems] = useState([...allPeople])
  const [scopeItems, setScopeItems] = useState([...allScopes])

  const [projectOrder, setProjectOrder] = useState([...allProjects])
  const [peopleOrder, setPeopleOrder] = useState([...allPeople])
  const [scopeOrder, setScopeOrder] = useState([...allScopes])

  const [s3Obj, sets3Obj] = useState(null)

  // Refs for each column's container
  const leftColumnRef = React.useRef(null)
  const rightColumnRef = React.useRef(null)

  // Swapy instances for drag and drop in each column
  const leftSwapyRef = React.useRef(null)
  const rightSwapyRef = React.useRef(null)

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  // Reset items when props change or dialog opens
  useEffect(() => {
    if (isOpen) {
      setProjectItems([...allProjects])
      setPeopleItems([...allPeople])
      setScopeItems([...allScopes])
    }
  }, [allProjects, allPeople, allScopes, isOpen])

  // Initialize drag-and-drop with Swapy for left column (always projects)
  useEffect(() => {
    if (leftColumnRef.current && isOpen) {
      // Clean up existing swapy instance
      if (leftSwapyRef.current) {
        leftSwapyRef.current.destroy()
      }

      // Create new swapy instance for vertical drag and drop
      leftSwapyRef.current = createSwapy(leftColumnRef.current, {
        animation: 'dynamic',
        dragAxis: 'y',
        swapMode: 'hover',
        autoScrollOnDrag: true
      })

      // Handle swap events
      leftSwapyRef.current.onSwapEnd((event) => {
        if (event.hasChanged) {
          // Rearrange project items based on the new order
          const newProjects = []
          event.slotItemMap.asArray.forEach((item) => {
            const foundItem = allProjects.find(
              (i) => i.project_id === item.item
            )
            if (foundItem) {
              newProjects.push(foundItem)
            }
          })
          setProjectOrder(newProjects)
        }
      })
    }

    return () => {
      // Clean up
      if (leftSwapyRef.current) {
        leftSwapyRef.current.destroy()
        leftSwapyRef.current = null
      }
    }
  }, [leftColumnRef, isOpen, allProjects])

  // Initialize drag-and-drop with Swapy for right column (people or scopes)
  useEffect(() => {
    if (rightColumnRef.current && isOpen) {
      // Clean up existing swapy instance
      if (rightSwapyRef.current) {
        rightSwapyRef.current.destroy()
      }

      // Create new swapy instance for vertical drag and drop
      rightSwapyRef.current = createSwapy(rightColumnRef.current, {
        animation: 'dynamic',
        dragAxis: 'y',
        swapMode: 'hover',
        autoScrollOnDrag: true
      })

      // Handle swap events
      rightSwapyRef.current.onSwapEnd((event) => {
        if (event.hasChanged) {
          const rightItems = itemType === 'scope' ? allScopes : allPeople
          const newItems = []

          event.slotItemMap.asArray.forEach((item) => {
            const foundItem = rightItems.find((i) =>
              itemType === 'scope'
                ? i.scope_id === item.item
                : i.people_id === item.item
            )
            if (foundItem) {
              newItems.push(foundItem)
            }
          })

          if (itemType === 'scope') {
            setScopeOrder(newItems)
          } else {
            setPeopleOrder(newItems)
          }
        }
      })
    }

    return () => {
      // Clean up
      if (rightSwapyRef.current) {
        rightSwapyRef.current.destroy()
        rightSwapyRef.current = null
      }
    }
  }, [rightColumnRef, isOpen, allPeople, allScopes, itemType])

  const handleSaveOrder = () => {
    let result

    // Return both updated projects and the item type being reordered
    if (itemType === 'project') {
      result = {
        projects: projectOrder,
        people: peopleOrder
      }
    } else if (itemType === 'people') {
      result = {
        projects: projectOrder,
        people: peopleOrder
      }
    } else if (itemType === 'scope') {
      result = {
        projects: projectOrder,
        scopes: scopeOrder
      }
    }

    onSaveOrder(result)
    onClose()
  }

  // Render item content based on item type
  const renderItemContent = (item, type) => {
    if (type === 'people') {
      return (
        <div className="flex items-center gap-2">
          <ImageAvatarFallback
            name={item.people_name}
            profilePic={item.profile_picture ?? item.profile_picture_location}
            s3Obj={s3Obj}
            style={{
              height: '20px',
              minWidth: '20px',
              maxWidth: '20px',
              fontSize: '12px'
            }}
          />
          <span className="text-xs font-medium">{item.people_name}</span>
        </div>
      )
    } else if (type === 'project') {
      return (
        <div className="flex items-center gap-2">
          <span className="text-xs font-medium line-clamp-1">
            {item.project_name}
          </span>
        </div>
      )
    } else if (type === 'scope') {
      return (
        <div className="flex items-center gap-2">
          <span className="text-xs font-medium">{item.scope_name}</span>
        </div>
      )
    }
    return null
  }

  // Get the title for right column
  const getRightColumnTitle = () => {
    return itemType === 'scope' ? 'Scopes' : 'People'
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[650px] max-h-[80vh] overflow-hidden flex flex-col">
        <DialogHeader>
          <DialogTitle>Reorder</DialogTitle>
          <DialogDescription>
            Drag and drop to reorder the items in the matrix
          </DialogDescription>
        </DialogHeader>

        <div className="flex-1 p-1 -mx-6 overflow-auto">
          <div className="flex gap-6 px-6">
            {/* Left column - Projects */}
            <div className="flex-1 ">
              <h3 className="mb-2 text-sm font-medium">Projects</h3>

              <div
                className="flex flex-col w-full gap-2 pb-2"
                ref={leftColumnRef}
              >
                {projectItems.map((item) => (
                  <div
                    key={item.project_id}
                    data-swapy-slot={item.project_id}
                    className="w-full"
                  >
                    <div
                      data-swapy-item={item.project_id}
                      className="flex items-center w-full gap-3 p-2 border rounded-md cursor-grab active:cursor-grabbing border-zinc-200 bg-zinc-50 group hover:bg-zinc-100"
                    >
                      <div className="flex items-center justify-center text-zinc-500">
                        <DragDotsIcon className="size-4" />
                      </div>
                      {renderItemContent(item, 'project')}
                    </div>
                  </div>
                ))}

                {projectItems.length === 0 && (
                  <p className="py-4 text-center text-zinc-500">
                    No projects to reorder
                  </p>
                )}
              </div>
            </div>

            <div className="">
              <div className="w-0.5 h-full bg-zinc-100" />
            </div>
            {/* Right column - People or Scopes */}
            <div className="flex-1">
              <h3 className="mb-2 text-sm font-medium">
                {getRightColumnTitle()}
              </h3>
              <div
                className="flex flex-col w-full gap-2 pb-2"
                ref={rightColumnRef}
              >
                {(itemType === 'scope' ? scopeItems : peopleItems).map(
                  (item) => (
                    <div
                      key={
                        itemType === 'scope' ? item.scope_id : item.people_id
                      }
                      data-swapy-slot={
                        itemType === 'scope' ? item.scope_id : item.people_id
                      }
                      className="w-full"
                    >
                      <div
                        data-swapy-item={
                          itemType === 'scope' ? item.scope_id : item.people_id
                        }
                        className="flex items-center w-full gap-3 p-2 border rounded-md cursor-grab active:cursor-grabbing border-zinc-200 bg-zinc-50 group hover:bg-zinc-100"
                      >
                        <div className="flex items-center justify-center text-zinc-500">
                          <DragDotsIcon className="size-4" />
                        </div>
                        {renderItemContent(
                          item,
                          itemType === 'scope' ? 'scope' : 'people'
                        )}
                      </div>
                    </div>
                  )
                )}

                {(itemType === 'scope'
                  ? scopeItems.length === 0
                  : peopleItems.length === 0) && (
                  <p className="py-4 text-center text-zinc-500">
                    No {itemType === 'scope' ? 'scopes' : 'people'} to reorder
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <DialogFooter className="mt-4">
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            className="mr-2"
          >
            Cancel
          </Button>
          <Button type="button" onClick={handleSaveOrder}>
            Save Order
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ReorderItemsDialog
