import React, { useState, useEffect, useRef } from 'react'
import { ImageAvatarFallback } from '@/components'
import { PlusIcon, CrossIcon, PencilIcon } from '@/components/Icons/Icons'
import { initalizeS3 } from '@/utils/AWS'
import AddRoleDialog from './AddRoleDialog'

const PeopleCells = ({
  people,
  onPeopleOrderChange,
  onAddPeople,
  onRemovePeople,
  onSaveRole // Function to save roles
}) => {
  const containerRef = useRef(null)
  const [s3Obj, sets3Obj] = useState(null)
  const [peopleOrder, setPeopleOrder] = useState(people)
  const [selectedPerson, setSelectedPerson] = useState(null)
  const [showRoleDialog, setShowRoleDialog] = useState(false)

  console.log('people', people)
  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    initalizeData()
  }, [])

  useEffect(() => {
    setPeopleOrder(people)
  }, [people])

  const handleAddPeopleClick = () => {
    try {
      onAddPeople()
    } catch (error) {
      console.error('Error calling onAddPeople:', error)
    }
  }

  const handleAddRoleClick = (person) => {
    setSelectedPerson(person)
    setShowRoleDialog(true)
  }

  const handleSaveRole = (personId, role) => {
    if (onSaveRole) {
      onSaveRole(personId, role)
    }
    setShowRoleDialog(false)
  }

  return (
    <>
      <div className="flex min-w-max" ref={containerRef}>
        {peopleOrder.map((person) => (
          <div
            className="relative flex items-center justify-center p-2.5 px-1.5 border-r w-36 slot top border-zinc-200 group pr-2"
            key={person.people_id}
            style={{ pointerEvents: 'auto' }}
          >
            <div
              className="flex flex-col justify-between gap-1 font-medium text-xxs item item-a"
              style={{ pointerEvents: 'auto' }}
            >
              <div className="flex items-center justify-between w-full gap-1">
                <ImageAvatarFallback
                  name={person.name}
                  profilePic={
                    person.profile_picture ?? person.profile_picture_location
                  }
                  style={{
                    height: '24px',
                    minWidth: '24px',
                    maxWidth: '24px',
                    fontSize: '12px'
                  }}
                  openImage={() => {}}
                  s3Obj={s3Obj}
                />
                <div>
                  <div className="ml-1 line-clamp-1">{person.people_name}</div>
                  {person.people_role ? (
                    <span
                      className="truncate ml-1 text-[10px] flex items-center gap-1 cursor-pointer"
                      onClick={() => handleAddRoleClick(person)}
                    >
                      {person.people_role}{' '}
                      <PencilIcon className="size-2.5 group-hover:opacity-100 opacity-0" />
                    </span>
                  ) : (
                    <span
                      className="ml-1 text-[10px] text-zinc-500 flex items-center gap-1 cursor-pointer"
                      onClick={() => handleAddRoleClick(person)}
                    >
                      add role{' '}
                      <PencilIcon className="size-2.5 group-hover:opacity-100 opacity-0" />
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div
              onClick={() => onRemovePeople(person.people_id)}
              className="absolute transition-all opacity-0 cursor-pointer top-1 right-1 group-hover:opacity-100 text-zinc-500"
            >
              <CrossIcon className="size-2.5" />
            </div>
          </div>
        ))}

        <div className="flex items-center justify-center p-1 px-3 border-r w-36 slot top border-zinc-200">
          <button
            className="flex flex-col items-center justify-center w-full gap-1 font-medium text-zinc-600 text-xxs"
            aria-label="Add people to matrix"
            type="button"
            onClick={handleAddPeopleClick}
          >
            <PlusIcon className="text-zinc-600 size-4" />
            <span>Add People</span>
          </button>
        </div>
      </div>

      {/* Add Role Dialog */}
      {showRoleDialog && selectedPerson && (
        <AddRoleDialog
          isOpen={showRoleDialog}
          onClose={() => setShowRoleDialog(false)}
          person={selectedPerson}
          currentRole={selectedPerson.people_role || ''}
          onSaveRole={handleSaveRole}
          s3Obj={s3Obj}
        />
      )}
    </>
  )
}

export default PeopleCells
