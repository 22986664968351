import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Checkbox } from '@/components/ui/checkbox'
import { ImageAvatarFallback } from '@/components'
import { initalizeS3 } from '@/utils/AWS'
import { fetchPeople } from '@/store/People/Actions'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from '@/components/ui/dialog'
import SearchNew from '@/sections/ListFilter/SearchNew'
import Button2 from '@/components/Button/Button2'
import { RedirectIcon } from '@/components/Icons/Icons'

const AddPeopleDialog = ({ isOpen, onClose, onAddPeople, existingPeople }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [dialogLoading, setDialogLoading] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [selectedPeople, setSelectedPeople] = useState([])
  const [s3Obj, setS3Obj] = useState(null)

  const dispatch = useDispatch()

  // Initialize S3 for avatar loading
  useEffect(() => {
    async function initializeS3Data() {
      const s3 = await initalizeS3()
      setS3Obj(s3)
    }
    initializeS3Data()
  }, [])

  // Reset dialog state when opening
  useEffect(() => {
    if (isOpen) {
      setSearchTerm('')
      setSelectedPeople([])
      fetchSearchResults()
    }
  }, [isOpen, existingPeople])

  // Search functionality
  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => {
        fetchSearchResults()
      }, 500)
      return () => clearTimeout(timeoutId)
    }
  }, [searchTerm, isOpen])

  const fetchSearchResults = () => {
    setDialogLoading(true)
    const req = {
      page_num: 1,
      page_size: 50,
      keyword: searchTerm
    }

    dispatch(
      fetchPeople(req, {}, (data) => {
        // Filter out people already in the matrix
        const availablePeople =
          data?.peopleList?.filter(
            (person) => !existingPeople.some((p) => p.people_id === person.id)
          ) || []
        setSearchResults(availablePeople)
        setDialogLoading(false)
      })
    )
  }

  const togglePersonSelection = (person) => {
    setSelectedPeople((prevSelected) => {
      if (prevSelected.some((p) => p.people_id === person.id)) {
        return prevSelected.filter((p) => p.people_id !== person.id)
      } else {
        return [
          ...prevSelected,
          {
            people_id: person.id,
            people_name: person.name,
            people_role: null,
            profile_picture: person.profile_picture
          }
        ]
      }
    })
  }

  const handleAddPeople = () => {
    onAddPeople(selectedPeople)
    onClose()
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[700px]">
        <DialogHeader>
          <DialogTitle>Add People</DialogTitle>
          <DialogDescription>
            Search and select people to add to the matrix
          </DialogDescription>
        </DialogHeader>

        {/* Dialog Content */}
        <div className="py-4">
          <div className="mb-4">
            <SearchNew
              value={searchTerm}
              onChange={(value) => setSearchTerm(value)}
              placeholder="Search people..."
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>

          <div className="max-h-[300px] overflow-y-auto">
            {dialogLoading ? (
              <div className="flex items-center justify-center h-40">
                <span className="text-gray-500">Loading...</span>
              </div>
            ) : searchResults.length === 0 ? (
              <div className="flex items-center justify-center h-40">
                <span className="text-gray-500">No people found</span>
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-2">
                {searchResults.map((person) => (
                  <div
                    key={person.id}
                    className={`flex items-center p-2 border rounded-md cursor-pointer ${
                      selectedPeople.some((p) => p.id === person.id)
                        ? 'border-zinc-300 bg-zinc-50'
                        : 'border-zinc-100 hover:bg-zinc-50'
                    }`}
                    onClick={() => togglePersonSelection(person)}
                  >
                    <div className="flex items-center w-full gap-3 group">
                      <Checkbox
                        checked={selectedPeople.some(
                          (p) => p.people_id === person.id
                        )}
                        // onCheckedChange={() => togglePersonSelection(person)}
                      />
                      <ImageAvatarFallback
                        name={person.name}
                        profilePic={
                          person.profile_picture ??
                          person.profile_picture_location
                        }
                        style={{
                          height: '32px',
                          minWidth: '32px',
                          maxWidth: '32px',
                          fontSize: '14px'
                        }}
                        openImage={() => {}}
                        s3Obj={s3Obj}
                      />
                      <span className="text-sm font-medium">{person.name}</span>
                      <button
                        className="flex items-center invisible gap-1 ml-auto text-xs font-medium group-hover:visible"
                        onClick={(e) => {
                          e.stopPropagation()
                          window.open(
                            `/library/people-resume/${person.id}`,
                            '_blank'
                          )
                        }}
                      >
                        <RedirectIcon className="size-3" />
                        View Profile
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <button className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200">
              Cancel
            </button>
          </DialogClose>
          <Button2
            onClick={handleAddPeople}
            disabled={selectedPeople.length === 0}
            primary
          >
            Add {selectedPeople.length > 0 ? `(${selectedPeople.length})` : ''}
          </Button2>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddPeopleDialog
